import React from "react";
import { Layout } from "antd";
import { CodarxHeader, CodarxContent, CodarxSider } from "./style";
import SidebarContent from "./sidebar";
import HeaderContent from "./header";

const PageLayout = ({ children }) => {
  return (
    <Layout theme="light">
      <CodarxSider
        breakpoint={"lg"}
        theme="light"
        collapsedWidth={0}
        trigger={null}
        style={{ overflowY: "auto" }}
      >
        <SidebarContent />
      </CodarxSider>
      {/* <Layout> */}
      <CodarxHeader style={{ zIndex: 5 }}>
        <HeaderContent />
      </CodarxHeader>
      <CodarxContent>{children}</CodarxContent>
      {/* </Layout> */}
    </Layout>
  );
};

export default PageLayout;
