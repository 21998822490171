import React, { lazy, useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { Routes as Switch, Route, Navigate } from 'react-router-dom'
import { GetUserByRefresh, LogoutBlacklist } from './services/auth'
import PageLayout from './layout'
import { actions as authActions } from './store/authRedux/actions'

const CategoriesPage = lazy(() => import('./pages/CategoriesPage'))
const Dashboard = lazy(() => import('./pages/Dashboard'))
const CustomersPage = lazy(() => import('./pages/CustomersPage'))
const ProductsPage = lazy(() => import('./pages/ProductsPage'))
const PaymentsPage = lazy(() => import('./pages/PaymentsPage'))
const OrdersPage = lazy(() => import('./pages/OrdersPage'))
const SalesPage = lazy(() => import('./pages/SalesPage'))
const RegionsPage = lazy(() => import('./pages/RegionsPage'))
const DiscountsPage = lazy(() => import('./pages/DiscountsPage'))
const GiftCardsPage = lazy(() => import('./pages/GiftCardsPage'))
const SettingsPage = lazy(() => import('./pages/Settings'))
const WarehousesPage = lazy(() => import('./pages/Warehouses'))
const StaffsPage = lazy(() => import('./pages/StaffsPage'))
const NotificationsPage = lazy(() => import('./pages/NotificationsPage'))
const VendorsPage = lazy(() => import('./pages/VendorsPage'))
const VendorProductPage = lazy(() => import('./pages/VendorProductPage'))
const DeliveryPage = lazy(() => import('./pages/DeliveryPage'))
const PeykRequestsPage = lazy(() => import('./pages/PeykRequests'))
const DataEntryPage = lazy(() => import('./pages/DataEntryPage'))
const SuperMarketsPage = lazy(() => import('./pages/SuperMarketsPage'))
const AdminGroups = lazy(() => import('./pages/AdminGroups'))

const JWTDELAY = 250000

const BasePage = () => {
  const dispatch = useDispatch()

  const { refreshToken } = useSelector(
    ({ auth }) => ({
      refreshToken: auth.refresh,
      accessToken: auth.access,
    }),
    shallowEqual,
  )

  // useEffect(() => {
  //   try {
  //     const refreshAccessToken = setInterval(async () => {
  //       await GetUserByRefresh(refreshToken).then((res) => {
  //         dispatch(authActions.refresh(res.access))
  //       })
  //     }, JWTDELAY)
  //     return () => {
  //       clearInterval(refreshAccessToken)
  //     }
  //   } catch (err) {
  //     if (!err.response) return
  //     else {
  //       LogoutBlacklist(refreshToken)
  //       dispatch(authActions.logout())
  //     }
  //   }
  // }, [])

  return (
    <PageLayout>
      <Switch>
        <Route path="*" element={<Navigate to={'/'} replace />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/categories/*" exact element={<CategoriesPage />} />
        <Route path="/customers/*" exact element={<CustomersPage />} />
        <Route path="/products/*" exact element={<ProductsPage />} />
        <Route path="/payments/*" exact element={<PaymentsPage />} />
        <Route path="/orders/*" exact element={<OrdersPage />} />
        <Route path="/sales/*" exact element={<SalesPage />} />
        <Route path="/regions/*" exact element={<RegionsPage />} />
        <Route path="/discounts/*" exact element={<DiscountsPage />} />
        <Route path="/gift-cards/*" exact element={<GiftCardsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/warehouses/*" element={<WarehousesPage />} />
        <Route path="/staffs/*" exact element={<StaffsPage />} />
        <Route path="/notifications/*" exact element={<NotificationsPage />} />
        <Route path="/vendors/*" exact element={<VendorsPage />} />
        <Route path="/vendor-product" exact element={<VendorProductPage />} />
        <Route path="/delivery" exact element={<DeliveryPage />} />
        <Route path="/peyk-requests" exact element={<PeykRequestsPage />} />
        <Route path="/data-entry/*" exact element={<DataEntryPage />} />
        <Route path="/admin-groups/*" exact element={<AdminGroups />} />
        <Route path="/supermarkets/*" exact element={<SuperMarketsPage />} />
      </Switch>
    </PageLayout>
  )
}

export default BasePage
