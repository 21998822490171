import { Input, TimePicker } from 'antd'
import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import 'antd/es/locale/fa_IR'

import styles from './styles.module.css'

import { useSelector } from 'react-redux'
import { getDateByTimeString, _getDateDiff } from 'utils/_helpers'

import moment from 'moment'

import './picker.css'

const CodarxTimePicker = ({
  width,
  height,
  placeholder,
  bgContainer,
  value,
  ...props
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const InputRef = useRef()

  const [focused, setFocused] = useState(false)
  const [open, setOpen] = useState(false)

  const [isOccupied, setIsOccupied] = useState(focused || value)

  const _getBaseColor = () => {
    return isOccupied ? colorPalette.primary.op_10 : colorPalette.text.op_5
  }

  useEffect(() => {
    let cond = focused || value
    cond != isOccupied && setIsOccupied(cond)
  }, [focused, value])

  return (
    <>
      <div
        onClick={() => {
          InputRef.current.focus()
        }}
        className={`${styles.content_container}`}
      >
        <div
          onClick={() => setOpen(!open)}
          style={{
            width,
            height,
            border: `1px solid ${_getBaseColor()}`,
            background: bgContainer,
          }}
          className={styles.input_container}
        >
          <TimePicker
            ref={InputRef}
            onFocus={() => setFocused(true)}
            onBlur={() => setFocused(false)}
            suffixIcon={<></>}
            placeholder=""
            style={{
              border: 'none !important',
              width: '100%',
              flexDirection: 'row-reverse',
            }}
            defaultValue={
              value && typeof value == 'string'
                ? getDateByTimeString(value)
                : value
            }
            {...props}
          />

          <span
            style={{
              color: isOccupied ? _getBaseColor() : colorPalette.text.op_3,
              fontSize: isOccupied ? 10 : 14,
              top: isOccupied ? 4 : 'calc(50% - 11px)',
              right: isOccupied ? 12 : 35,
            }}
            className={styles.placeholder}
          >
            {placeholder}
          </span>
        </div>
      </div>
    </>
  )
}

CodarxTimePicker.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  message: PropTypes.object,
  ...Input.propTypes,
}

CodarxTimePicker.defaultProps = {
  placeholder: '',
  height: 52,
  bgContainer: 'transparent',
}

export default CodarxTimePicker
