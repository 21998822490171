import React, { lazy } from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import BasePage from './BasePage'

const AuthPage = lazy(() => import('./pages/AuthPage'))

const Routes = () => {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({ isAuthorized: auth.user != null }),
    shallowEqual,
  )
  return <>{isAuthorized ? <BasePage /> : <AuthPage />}</>
}

export default Routes
