import styled from "styled-components";
export default styled.div`
  .logo_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #e2e3e4;
    height: 78px;
    img {
      max-width: 100%;
    }
  }
  .ant-menu-item:hover .ant-menu-title-content a {
    font-weight: 700;
    color: rgb(82, 111, 229);
  }
  .menu {
    border-left: 0;
    padding: 1rem 0;

    [class^="iconly-"] {
      font-size: 1.5rem;
    }
    li {
      &:after {
        display: none;
      }
      &,
      .ant-menu-submenu-title {
        min-height: 60px;
        line-height: 60px;
      }
      &.ant-menu-item-selected {
        background-color: #f6f8ff;
        border-left: 0;
      }
      &.ant-menu-item-selected a {
        font-weight: 700;
        color: #2445cd;
      }
      a {
        font-size: 14px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 500;
        color: #525252;
      }
      a span {
        font-size: 22px;
      }
    }
  }
`;
