import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'
import styled from 'styled-components'

import styles from './styles.module.css'

const CustomBtn = styled(Button)`
  width: ${({ width }) => (width ? width + 'px' : '100%')} !important;
  height: ${({ height }) => (height ? height + 'px' : '100%')} !important;
  background: ${(props) => props.ghost && 'white !important'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 9px 15px;
  border-radius: 4px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  box-shadow: none;
`

const CodarxBtn = ({ width, height, icon, ...props }) => {
  return (
    <CustomBtn width={width} height={height} icon={icon} {...props}></CustomBtn>
  )
}

CodarxBtn.propTypes = {
  ...Button.propTypes,
  icon: PropTypes.element,
  width: PropTypes.number,
  height: PropTypes.number,
  backgroundColor: PropTypes.string,
}

CodarxBtn.defaultProps = {
  icon: <></>,
  type: 'primary',
}

export default CodarxBtn
