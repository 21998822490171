import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { Form, notification } from 'antd'

import * as Yup from 'yup'

import { FormAlert } from '../alerts'
import CodarxInput from '../codarxInput'
import CodarxCheckBox from '../codarxCheckBox'
import CodarxLink from '../codarxLink'
import { CodarxBtn } from '../buttons'

import styles from '../../pages/AuthPage/styles.module.css'

import { BasicLogin, GetUserByToken } from 'services/auth'

import { actions as authActions } from '../../store/authRedux/actions'
import { useFormik } from 'formik'

const initialValues = {
  username: undefined,
  password: undefined,
  remember: false,
}

const FormSchema = Yup.object({
  username: Yup.string()
    .min(3, 'نام کاربری باید حداقل 3 کاراکتر باشد')
    .required('وارد کردن این فیلد الزامی است'),
  password: Yup.string()
    .min(3, 'رمزعبور باید حداقل 3 کاراکتر باشد')
    .required('وارد کردن این فیلد الزامی است'),
  remember: Yup.bool(),
})

const LoginForm = () => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: FormSchema,
    onSubmit: (values) => {
      _loginUser({ ...values, remember: values.remember ? true : false })
    },
  })

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  const _loginUser = (values) => {
    setLoading(true)
    BasicLogin(values)
      .then((result) => {
        const { access, refresh } = result
        dispatch(authActions.login(access, refresh))
        notification.success({ message: 'خوش آمدید!' })
      })
      .catch((err) => {
        setLoading(false)
        setError('نام کاربری یا رمزعبور شما اشتباه است.')
      })
  }

  return (
    <div className={styles.page_form}>
      <div className={styles.form_body}>
        <h1 className={styles.title} style={{ color: colorPalette.text.title }}>
          ورود
        </h1>
        {error && (
          <div className={styles.form_alert}>
            <FormAlert type={'error'} message={error} />
          </div>
        )}
        <div className={styles.form_input}>
          <CodarxInput
            value={formik.values.username}
            onChange={(e) => formik.setFieldValue('username', e.target.value)}
            block={true}
            message={
              formik.errors.username && {
                type: 'error',
                rightComponent: formik.errors.username,
              }
            }
            type="text"
            placeholder={'نام کاربری'}
          />
        </div>
        <div className={styles.form_input}>
          <CodarxInput
            type="password"
            placeholder={'رمزعبور'}
            value={formik.values.password}
            onChange={(e) => formik.setFieldValue('password', e.target.value)}
            block={true}
            message={
              formik.errors.password && {
                type: 'error',
                rightComponent: formik.errors.password,
              }
            }
          />
        </div>
        <div style={{ width: '100%' }} className="flex-row-between">
          <CodarxCheckBox
            onChange={(e) => formik.setFieldValue('remember', e.target.checked)}
            checked={formik.values.remember}
          >
            <span style={{ fontSize: 12, color: colorPalette.text.op_3 }}>
              مرا بخاطر بسپار.
            </span>
          </CodarxCheckBox>
          <CodarxLink to={'/auth/forget-password'}>فراموشی رمز عبور</CodarxLink>
        </div>
      </div>
      <div className={styles.form_action}>
        <div className={styles.form_button}>
          <CodarxBtn
            height={40}
            onClick={() => formik.submitForm()}
            loading={loading}
            block={true}
          >
            ورود
          </CodarxBtn>
        </div>
        <div className={styles.form_button}>
          <CodarxBtn
            height={40}
            onClick={() => navigate('/auth/login-otp')}
            block={true}
            ghost={true}
          >
            ورود با تلفن همراه
          </CodarxBtn>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
