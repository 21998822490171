import React, { useMemo, useState } from "react";
import { AutoComplete, Input } from "antd";
import { useQuery } from "react-query";
import { getUsers } from "services/user";
import { useSearchDebounce } from "utils/useDebouncedSearch";

function CustomerSearch({ selectedUser, setSelectedUser }) {
  const [search, setSearch] = useSearchDebounce("");

  const { data } = useQuery(
    ["user-search", search],
    () => getUsers([], { search: search }),
    {
      enabled: Boolean(search.length > 1),
      refetchOnWindowFocus: false,
    }
  );

  const options = useMemo(() => {
    return data?.results.map((item) => ({
      value: item.full_name,
      label: <span>{item.full_name}</span>,
      entry: item,
    }));
  }, [data]);

  return (
    <AutoComplete
      allowClear
      showSearch={false}
      autoClearSearchValue
      options={options}
      style={{
        borderRadius: 8,
      }}
      onSelect={(_, obj) => setSelectedUser(obj.entry.id)}
      onSearch={(value) => setSearch(value)}
    >
      <Input size="large" placeholder="جست و جو در کاربران..." />
    </AutoComplete>
  );
}

export { CustomerSearch };
