import { STATUS_DICTIONARY } from "constants";
import Jalaali from "jalaali-js";
import parsePhoneNumber from "libphonenumber-js";
import moment from "moment";
import store from "store/store";

let colorPalette;

export const setupBaseVaraiables = (_colorPallette) => {
  colorPalette = _colorPallette;
};

export const _changeLayoutBackground = (color) => {
  document.body.style.backgroundColor = color;
};

export const _getDateDiff = (t1, t2) => {
  var diffMS = t1 - t2;

  var diffS = parseInt(diffMS / 1000);
  var diffM = parseInt(diffS / 60);
  var diffH = parseInt(diffM / 60);
  var diffD = parseInt(diffH / 24);
  var diffMO = parseInt(diffD / 30);
  var diffY = parseInt(diffMO / 12);

  if (diffY > 0) {
    return `${diffY} سال پیش `;
  } else if (diffMO > 0) {
    return `${diffMO} ماه پیش `;
  } else if (diffD > 0) {
    return `${diffD} روز پیش `;
  } else if (diffH > 0) {
    return `${diffH} ساعت پیش `;
  } else if (diffM > 0) {
    return `${diffM} دقیقه پیش `;
  } else {
    return `لحظاتی پیش `;
  }
};

export const streamLineOptions = (options) => {
  return Object.entries(options).map((item) => {
    return {
      id: item[0],
      ...item[1],
    };
  });
};

export const tableBodyParser = (tableBody) => {
  return tableBody?.map((item) => {
    return {
      ...item,
    };
  });
};

export const removeEmpty = (obj) => {
  Object.keys(obj).forEach(
    (k) => !obj[k] && obj[k] !== undefined && delete obj[k]
  );
  return obj;
};

export const isCommonField = (field) => {
  const fields = [
    "id",
    "name",
    "sku",
    "slug",
    "product_count",
    "price",
    "sale_price",
    "inventory",
    "city",
    "state",
    "customer_name",
    "created_date",
    "total_amount",
    "amount",
    "start_work_hour",
    "end_work_hour",
    "code",
  ];

  return fields.includes(field);
};

export const tableHeadParser = (fields, options) => {
  return fields.map((item) => {
    return {
      dataIndex: item,
      title: options.filter((target) => target.id === item)[0]?.label,
      sorter: isCommonField(item) ? () => {} : null,
      render: (text) => {
        if (item === "vendor" && text?.image)
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <img
                src={text?.image.file}
                width="55px"
                height="55px"
                style={{ objectFit: "contain" }}
                alt="logo"
              />
              <span>{text?.name}</span>
            </div>
          );
        if (item === "vendor")
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <span>{text?.name}</span>
            </div>
          );
        if (item === "status" && STATUS_DICTIONARY[text])
          return (
            <span
              style={{
                display: "inline-block",
                padding: "0.5rem",
                backgroundColor: STATUS_DICTIONARY[text].color,
                color: "#fff",
                borderRadius: "8px",
              }}
            >
              {STATUS_DICTIONARY[text].label}
            </span>
          );
        if (
          ["wallet_balance", "total_amount", "price", "sale_prie"].includes(
            item
          )
        ) {
          return <span>{text ? text.toLocaleString() : ""}</span>;
        }
        if (item == "is_used") {
          return <span>{text === true ? "بله" : "خیر"}</span>;
        }
        return text;
      },
    };
  });
};

export const channelsParser = (channels) => {
  const allChannels = {
    id: 0,
    label: "همه",
  };
  return [
    allChannels,
    ...channels.map(({ id, name, is_active }) => {
      return {
        value: id,
        label: name,
      };
    }),
  ];
};

export const _getStatusColor = (_status) => {
  switch (_status) {
    case "success":
      return colorPalette.success.op_4;
    case "completed":
      return colorPalette.success.op_4;
    case "failed":
      return colorPalette.danger.main;
    case "canceled":
      return colorPalette.danger.main;
    case "pending":
      return colorPalette.text.op_5;
    case "processing":
      return colorPalette.warning.main;
    case "sending":
      return colorPalette.primary.op_11;
    default:
      return colorPalette.text.main;
  }
};

export const _getPersianDate = (_date) => {
  const _newDate = Jalaali.toJalaali(_date);
  return `${_newDate.jy}/${_newDate.jm}/${_newDate.jd}`;
};

export const _formatPhoneNumber = (phoneNumberString) => {
  var _newPhone = parsePhoneNumber(phoneNumberString, "IR");
  return _newPhone.formatInternational();
};

export const getDateByTimeString = (time) => {
  if (time) {
    const d = moment().toDate();
    d.setHours(time.split(":")[0]);
    d.setMinutes(time.split(":")[1]);
    d.setSeconds(time.split(":")[2]);
    return moment(d);
  } else {
    return moment(new Date());
  }
};

export const _getFieldError = (form, field) => {
  if (form.errors[field] && form.touched[field]) {
    return {
      type: "error",
      rightComponent: form.errors[field],
    };
  }
};

export const IsUserPermitted = (model, type) => {
  const user = store.getState().auth.user;

  if (user.group && user.group[0]) {
    const permissions = user.group[0].permissions;

    const modelPermissions = permissions.filter(
      (perm) => perm.content_type == model
    );

    if (modelPermissions.length > 0) {
      return Boolean(
        modelPermissions.find((perm) => perm.codename.includes(type))
      );
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export function convertPerToEn(num) {
  const id = {
    "۰": "0",
    "۱": "1",
    "۲": "2",
    "۳": "3",
    "۴": "4",
    "۵": "5",
    "۶": "6",
    "۷": "7",
    "۸": "8",
    "۹": "9",
  };
  return num
    ? num.toString().replace(/[^0-9.]/g, function (w) {
        return id[w] || w;
      })
    : "";
}
