import './index.css'
// import 'antd/dist/antd.css'
import 'antd/dist/antd.variable.min.css'
import 'react-tabs/style/react-tabs.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'mapir-react-component/dist/index.css'

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import store, { persistor } from './store/store'

ReactDOM.render(
  <React.StrictMode>
    <App store={store} persistor={persistor}/>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
