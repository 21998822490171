import API from "utils/API";

export const getUsers = async (fields, params = {}) => {
  return API.get(`/account/admin/user/`, {
    params: { ...params, fields: `id,full_name,${fields.join(",")}` },
  });
};

export const getAdminGroups = async (fields, params = {}) => {
  return API.get(`/account/admin/group/`, {
    params: { ...params, fields: `id,name,${fields.join(",")}` },
  });
};

export const getSingleAdminGroup = async (id) => {
  return API.get(`/account/admin/group/${id}/`);
};

export const PostcreateAdminGroup = async (body) => {
  return API.post(`/account/admin/group/`, body);
};

export const updateSingleAdminGroup = async (id, body) => {
  return API.put(`/account/admin/group/${id}/`, body);
};

export const getPermissions = async () => {
  return API.get(`/account/admin/permissions/`, {
    params: { page_size: 1000 },
  });
};

export const getUserOptions = async (data) => {
  return API.options("/account/admin/user/", data);
};

export const getSingleUser = (id) => {
  return API.get(`/account/admin/user/${id}`);
};

export const updateSingleUser = (id, data) => {
  return API.patch(`/account/admin/user/${id}/`, data);
};

export const loadUsersCredit = (data) => {
  return API.post("/account/admin/user/load_user_wallet/", data);
};
