import { Input } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import "antd/es/locale/fa_IR";
import locale from "antd/es/date-picker/locale/fa_IR";
import moment from "moment";
import "./datepicker.css";
import { Datepicker } from "@ijavad805/react-datepicker";
import styles from "./styles.module.css";
import CodarxInput from "../codarxInput";
import { useSelector } from "react-redux";
import { _getDateDiff } from "utils/_helpers";
import jalaaliJs from "jalaali-js";

const BaseInput = styled(Input)`
  color: ${(props) => props.titleColor};
  width: 100%;
  height: 22px;
  padding: 4px 0px !important;
  text-align: right;
  margin-top: 8px;
  position: relative;
  & .pass_toggle {
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: calc(50% - 10px);
  }
  & .ant-input-prefix {
    width: 18px;
    height: 18px;
    position: absolute;
    top: ${(props) =>
      props.isOccupied ? "calc(50% - 8px)" : "calc(50% - 16px)"};
  }
  & input {
    padding-right: 25px !important;
  }
`;

const CodarxDatePicker = ({
  width,
  height,
  placeholder,
  bgContainer,
  ...props
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  const _dateFormat = "YYYY/MM/DD";

  const [focused, setFocused] = useState(false);
  const [open, setOpen] = useState(false);
  const [dateToShow, setDateToShow] = useState();
  const [date, setDate] = useState(moment(props.value).endOf("day"));

  const InputRef = useRef();

  const [isOccupied, setIsOccupied] = useState(false);

  const _getBaseColor = () => {
    return isOccupied ? colorPalette.primary.op_10 : colorPalette.text.op_5;
  };

  useEffect(() => {
    setIsOccupied(focused || InputRef.current?.input.value || props.value);
  }, [props.value, focused, InputRef.current?.input.value]);

  useEffect(() => {
    if (date & date.isValid()) {
      const _date = jalaaliJs.toJalaali(
        new Date(date.toISOString().split("T")[0].replaceAll("-", "/"))
      );
      setDateToShow(`${_date.jy}/${_date.jm}/${_date.jd}`);
      props.onChange(date.endOf("day"));
    }
  }, [date]);

  return (
    <div
      onClick={() => {
        InputRef.current.focus();
      }}
      className={`${styles.content_container}`}
    >
      <div
        onClick={() => setOpen(!open)}
        style={{
          width,
          height,
          border: `1px solid ${_getBaseColor()}`,
          background: bgContainer,
        }}
        className={styles.input_container}
      >
        <BaseInput
          ref={InputRef}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          bordered={false}
          contentEditable={false}
          value={dateToShow || ""}
          prefix={
            <i
              style={{
                position: "absolute",
                fontSize: 18,
                color: colorPalette.text.op_3,
                transition: ".3 all linear",
                cursor: "pointer",
              }}
              className="iconly-Calendar"
            ></i>
          }
          prefixCls={styles.prefix_inp}
          isOccupied={Boolean(isOccupied)}
          titleColor={colorPalette.text.title}
        />
        <span
          style={{
            color: Boolean(isOccupied)
              ? _getBaseColor()
              : colorPalette.text.op_3,
            fontSize: Boolean(isOccupied) ? 10 : 14,
            top: Boolean(isOccupied) ? 4 : "calc(50% - 11px)",
            right: Boolean(isOccupied) ? 12 : 35,
          }}
          className={styles.placeholder}
        >
          {placeholder}
        </span>
      </div>
      <Datepicker
        onChange={(val) => {
          setDate(val.endOf("day"));
          setOpen(false);
        }}
        input={<input placeholder="" />}
        adjustPosition={"right-bottom"}
      />
    </div>
  );
};

CodarxDatePicker.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  message: PropTypes.object,
  ...Input.propTypes,
};

CodarxDatePicker.defaultProps = {
  placeholder: "",
  height: 52,
  bgContainer: "transparent",
};

export default CodarxDatePicker;
