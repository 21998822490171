import React, { Component } from 'react'
import { Popover } from 'react-tiny-popover'
import styles from './styles.module.css'
import onClickOutside from 'react-onclickoutside'
import 'react-perfect-scrollbar/dist/css/styles.css'
import PerfectScrollbar from 'react-perfect-scrollbar'
import styled from 'styled-components'

const DropDownContainer = styled.div`
  border: ${({ borderColor }) => '1px solid ' + borderColor};
  border-radius: 4px;
  z-index: ${({ isPopoverOpen }) => (isPopoverOpen ? 3 : 2)};
  position: absolute;
  width: 100%;
  background-color: #fff;
  &:hover {
    border: ${({ borderHoverColor }) => '1px solid ' + borderHoverColor};
  }
`

class CodarxCustomDropDown extends Component {
  constructor(props) {
    super(props)
    this.state = { isPopoverOpen: false, selected: null }
  }
  handleClickOutside = (evt) => {
    this.setState({ isPopoverOpen: false })
  }
  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({ selected: this.props.defaultValue })
    }
  }
  render() {
    return (
      <div
        className={styles.dropdown}
        style={{
          color: this.props.textColor,
          width: this.props.width,
          height: this.props.height,
        }}
      >
        <DropDownContainer
          defaultValue={this.props.defaultValue}
          onChange={(e) => this.props.onChange(e)}
          isPopoverOpen={this.state.isPopoverOpen}
          borderColor={this.props.borderColor}
          borderHoverColor={this.props.borderHoverColor}
        >
          <div
            style={{
              color: this.props.textColor,
              width: this.props.width,
              height: this.props.height,
            }}
            className={styles.dropdown_container}
            onClick={() => {
              this.setState({ isPopoverOpen: !this.state.isPopoverOpen })
            }}
          >
            <div className={styles.dropdown_text}>
              {this.state.selected === null
                ? this.props.placeholder
                : this.state.selected.value}
            </div>

            <i
              className={
                this.state.isPopoverOpen
                  ? 'iconly-Arrow-Up-2'
                  : 'iconly-Arrow-Down-2'
              }
              style={{ color: this.props.textColor }}
            ></i>
          </div>

          <div
            style={{
              display: this.state.isPopoverOpen ? '' : 'none',
              maxHeight: this.props.maxHeight + 2,
            }}
            className={'ignore-react-onclickoutside ' + styles.dropdown_layout}
          >
            <PerfectScrollbar
              style={{
                height: '100%',
                maxHeight: this.props.maxHeight,
              }}
            >
              <div className={styles.dropdown_rtl}>
                {this.props.options.map((item, index) => (
                  <div
                    onClick={() => {
                      this.setState(
                        { selected: item, isPopoverOpen: false },
                        () => this.props.onChange(item),
                      )
                    }}
                    key={index}
                    className={styles.dropdown_row}
                    style={{
                      color:
                        this.state.selected?.key === item.key
                          ? this.props.activeColor
                          : this.props.textColor,
                      height: this.props.rowHeight,
                      backgroundColor:
                        this.state.selected?.key === item.key
                          ? this.props.activeBgColor
                          : '',
                    }}
                  >
                    {item.value}
                  </div>
                ))}
              </div>
            </PerfectScrollbar>
          </div>
        </DropDownContainer>
      </div>
    )
  }
}

CodarxCustomDropDown.defaultProps = {
  positions: ['bottom'],
  width: '100%',
  height: '40px',
  maxHeight: 188,
  popoverHeight: '100%',
  rowHeight: '47px',
  options: [
    { key: 'مقدار 1', value: 'مقدار 1' },
    { key: 'مقدار 2', value: 'مقدار 2' },
  ],
  onChange: () => {},
}
export default onClickOutside(CodarxCustomDropDown)
