import API from "utils/API";

const GET_CUTSTOMER_URL = (id) => `/account/admin/user/${id}/`;
const GET_WALLET_URL = (id) => `/payment/wallet/${id}/user_wallet/`;
const ADD_TRANSACTION_URL = (id) => `/payment/admin/wallet_transaction/`;
const GET_CUSTOMER_PAYMENTS = () => `/payment/admin/payment/`;
const GET_CUSTOMER_WALLET_PAYMENTS = (user) =>
  `/payment/admin/wallet_transaction/?wallet__user=${user}`;
const ACTIVATE_CUSTOMER_URL = (id) =>
  `/account/admin/user/${id}/activate_user/`;
const DEACTIVATE_CUSTOMER_URL = (id) =>
  `/account/admin/user/${id}/deactivate_user/`;
const SAVE_CUSTOMER_URL = (id) => `/account/admin/user/${id}/`;
const GET_ADDRESS_LIST_URL = (user) => `/account/admin/address/?user=${user}`;
const EDIT_ADDRESS_URL = (id, user) =>
  `/account/admin/address/${id}/?user=${user}`;
const CREATE_ADDRESS_URL = () => `/account/admin/address/`;
const GET_CUSTOMER_SEGMENT = () => `/account/admin/segment/`;
const DELETE_SEGMENT_URL = (id) => `/account/admin/segment/${id}`;
const GET_SUPERMARKETS_URL = () => `/account/admin/supermarket_info/`;
const GET_SINGLE_SUPERMARKETS_URL = (id) => `/account/admin/supermarket_info/${id}/`;

const CREATE_USER_URL = "/account/admin/user/";

export const GetCustomer = (id) => {
  const url = GET_CUTSTOMER_URL(id);
  return API.get(url);
};

export const GetWallet = (id) => {
  return API.get(GET_WALLET_URL(id));
};

export const AddTransactionCustomer = (data) => {
  return API.post(ADD_TRANSACTION_URL(), data);
};

export const GetCustomerPayments = (id, params) => {
  return API.get(GET_CUSTOMER_PAYMENTS(id), { params });
};
export const GetCustomerWalletPayments = (id, params) => {
  return API.get(GET_CUSTOMER_WALLET_PAYMENTS(id), { params });
};
export const CreateUser = (data) => {
  return API.post(CREATE_USER_URL, data);
};
export const ActivateUser = (id) => {
  return API.get(ACTIVATE_CUSTOMER_URL(id));
};
export const DeactivateUser = (id) => {
  return API.get(DEACTIVATE_CUSTOMER_URL(id));
};
export const SaveCustomer = (id, data) => {
  return API.patch(SAVE_CUSTOMER_URL(id), data);
};
export const PatchCustomer = (id, data) => {
  return API.patch(SAVE_CUSTOMER_URL(id), data);
};
export const GetAddressList = (user) => {
  return API.get(GET_ADDRESS_LIST_URL(user));
};
export const EditAddressCustomer = (user, id, data) => {
  return API.put(EDIT_ADDRESS_URL(id, user), data);
};
export const CreateAddressCustomer = (data) => {
  return API.post(CREATE_ADDRESS_URL(), data);
};
export const DeleteAddressCustomer = (id, user) => {
  return API.delete(EDIT_ADDRESS_URL(id, user));
};
export const GetCustomerSegment = () => {
  return API.get(GET_CUSTOMER_SEGMENT());
};
export const DeletSegment = (id) => {
  return API.delete(DELETE_SEGMENT_URL(id));
};

export const AddSegment = (data) => {
  return API.post(GET_CUSTOMER_SEGMENT(), data);
};

export const GetSuperMarketsList = (fields, params = {}) => {
  console.log(params)
  return API.get(GET_SUPERMARKETS_URL(), {
    params: { ...params, fields: undefined },
  });
};

export const GetSingleSuperMarket = (id) => {
  return API.get(GET_SINGLE_SUPERMARKETS_URL(id));
};

export const UpdateSingleSuperMarket = (id, data) => {
  return API.patch(GET_SINGLE_SUPERMARKETS_URL(id), data);
};

export const GetSuperMarketsOptions = async (data) => {
  return API.options(GET_SUPERMARKETS_URL(), data);
};
