import React from 'react'
import { CustomSelect, MenuBordered } from './style'
import { Dropdown, Menu, Select } from 'antd'

const { Option } = Select

export const CodarxDropDown = ({
  options,
  onChange,
  currentValue,
  type,
  fullWidth,
  ...props
}) => {
  return (
    <CustomSelect
      defaultValue={currentValue}
      onChange={onChange}
      className={type || ''}
      style={{ width: fullWidth && '100%' }}
      {...props}
    >
      {options &&
        options.map((option) =>
          option.color ? (
            <Option style={{ color: option.color }} value={option.value}>
              {option.label}
            </Option>
          ) : (
            <Option value={option.value}>{option.label}</Option>
          ),
        )}
    </CustomSelect>
  )
}

export const UnstyledDropDown = ({ childeren, options }) => {
  return (
    <div>
      <Dropdown
        overlay={
          <>
            <MenuBordered>
              {options.map((item) => (
                <Menu.Item key={item.key} onClick={item.function}>
                  {item.label}
                </Menu.Item>
              ))}
            </MenuBordered>
          </>
        }
        trigger={['click']}
      >
        {childeren}
      </Dropdown>
    </div>
  )
}
