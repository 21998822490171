import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomerSearch, VendorSearch } from "./partials";
import { STATUS_DICTIONARY } from "constants";
import { Button, DatePicker, Select } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

function OrderFilters() {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [status, setStatus] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("", {
      state: {
        ...state,
        vendor: selectedVendor,
        user: selectedUser,
        status,
        start_date: startAt,
        end_date: endAt,
      },
    });
  };

  const handleClearFilters = () => {
    navigate("", {
      state: {
        page: state?.page,
        search: state?.search,
        page_size: state?.page_size,
      },
    });
    setSelectedUser(null);
    setSelectedVendor(null);
    setStatus(null);
    setStartAt(null);
    setEndAt(null);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="pr_table_header"
      style={{
        borderTop: "1px solid " + colorPalette.text.op_8,
        borderBottom: "1px solid " + colorPalette.text.op_8,
        display: "flex",
        gap: "0.5rem",
      }}
    >
      <CustomerSearch
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
      />
      <VendorSearch
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
      />
      <RangePicker
        onCalendarChange={(dates) => {
          setStartAt(moment(dates[0]).format("DD-MM-YYYY"));
          setEndAt(moment(dates[1]).format("DD-MM-YYYY"));
        }}
      />
      <Select
        value={status}
        placeholder="وضعیت"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onChange={(val) => setStatus(val)}
        style={{
          borderRadius: 8,
          width: "200px",
        }}
        bordered={false}
        options={Object.keys(STATUS_DICTIONARY).map((d) => ({
          value: d,
          label: STATUS_DICTIONARY[d].label,
        }))}
      />
      <Button
        htmlType="submit"
        type="primary"
        style={{ marginRight: "auto", borderRadius: 4 }}
      >
        اعمال فیلترها
      </Button>
      <Button type="text" danger onClick={handleClearFilters}>
        حذف تمامی فیلتر ها
      </Button>
    </form>
  );
}

export default OrderFilters;
