import React from 'react'
import Mapir from 'mapir-react-component'

import { MapMarkerSvg } from 'assets/images'

const MapMarker = (props) => {
  return <Mapir.Marker Image={MapMarkerSvg} {...props} />
}

export default MapMarker
