import { notification } from 'antd'
import history from './history'
import axios from 'axios'
import qs from 'qs'

import { actions } from '../store/authRedux/actions'
import { GetUserByRefresh, LogoutBlacklist } from 'services/auth'

let store
const URLBlacklist = ['/account/auth']
const ENTRY_ROUTE = '/auth'

export const setUpInterceptorStore = (_store) => {
  store = _store
}

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params, {
      arrayFormat: 'repeat',
    })
  },
})

// API Request interceptor
API.interceptors.request.use(
  (config) => {
    const access_token = store.getState().auth.access

    if (access_token) {
      config.headers['Authorization'] = `Bearer ${access_token}`
      config.headers[`Accept-Language`] = 'fa'
    } else {
      !URLBlacklist.find((url) => url.includes(config.url)) &&
        history.push(ENTRY_ROUTE)
    }

    return config
  },
  (error) => {
    notification.error({
      message: 'Error',
    })
    Promise.reject(error)
  },
)

// API respone interceptor
API.interceptors.response.use(
  (response) => {
    return response.data
  },
  (error) => {
    if (!error.response) {
      notification.error({
        message: 'خطا در اتصال به اینترنت',
      })
    }

    // Remove token and redirect
    else if (error.response.status === 401) {
      const refreshToken = store.getState().auth.refresh
      return new Promise(async function (resolve, reject) {
        !URLBlacklist.find((url) => url.includes(error.config.url))
          ? await axios
              .post(
                `${process.env.REACT_APP_API_URL}/account/auth/refresh/`,
                { refresh: refreshToken },
                {
                  headers: {
                    'Content-Type': 'application/json',
                  },
                },
              )
              .then((res) => {
                store.dispatch(actions.refresh(res.access)).then((result) => {
                  return API.request(error.config)
                })
              })
              .catch((err) => {
                if (!error.response) {
                  notification.error({
                    message: 'خطا در اتصال به اینترنت',
                  })
                  resolve()
                } else {
                  reject()
                }
              })
          : resolve()
      })
        .then((result) => {})
        .catch((err) => {
          LogoutBlacklist(refreshToken)
          store.dispatch(actions.logout())
          notification.error({
            message: 'خطایی در احراز هویت رخ داده است. لطفا دوباره وارد شوید',
          })
        })
    } else if (error.response.status === 404) {
      notification.error({
        message: 'اطلاعات مورد نظر یافت نشد',
      })
    } else if (error.response.status === 400 || error.response.status === 402) {
      notification.error({
        message:
          Object.keys(error.response.data)[0] +
          ' : ' +
          error.response.data[Object.keys(error.response.data)[0]],
      })
    } else if (error.response.status === 429) {
      notification.error({
        message:
          'تعداد درخواست هاش ما از حد مجاز گذشته است لطفا بعد از یک دقیقه دوباره تلاش کنید',
      })
    } else if (error.response.status === 500) {
      notification.error({
        message: 'خطایی در سرور رخ داده است! لطفا بعدا تلاش کنید',
      })
    } else if (error.response.status === 508) {
      notification.error({
        message: 'خطا در اتصال به سرور',
      })
    }

    return Promise.reject(error)
  },
)

export default API
