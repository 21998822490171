import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const Card = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  background-color: white;

  & .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color: ${({ titleColor }) => titleColor};
    margin-bottom: ${({ titleOffset }) => titleOffset};
  }
`

const CodarxCard = ({ ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <Card titleColor={colorPalette.text.main} {...props}>
      {props.children}
    </Card>
  )
}

CodarxCard.propTypes = {
  width: PropTypes.string,
  width: PropTypes.string,
  borderRadius: PropTypes.string,
  padding: PropTypes.string,
  titleOffset: PropTypes.string,
}

CodarxCard.defaultProps = {
  width: '100%',
  height: 'auto',
  borderRadius: '4px',
  padding: '16px 24px 24px 24px',
  titleOffset: '24px',
}

export default CodarxCard
