import API from 'utils/API'

const CREATE_PRODUCT_URL = () => `/product/admin/product/`
const GET_PRODUCT_URL = (id) => `/product/admin/product/${id}/`
const GET_PRODUCT_AVAILABILITY_URL = (id) =>
  `/product/admin/product-availability/?product_variant=${id}/`
const GET_PRODUCT_CHANNEL_LISTING_URL = (id) =>
  `/product/admin/product-channel-listing/?product=${id}/`
const GET_PRODUCT_VARIANT_CHANNEL_LISTING_URL = (variant) =>
  `/product/admin/product-variant-channel-listing/?variant=${variant}&channel=/`
const UPDATE_PRODUCT_AVAILABILITY = () =>
  `/product/admin/product-availability/bulk_update/`
const UPDATE_PRODUCT_CHANNEL_LISTING_URL = () =>
  `/product/admin/product-variant-channel-listing/bulk_update/`
const GET_PRODUT_TYPE = (id) => `/product/admin/product-type/${id}/`
const CREATE_PRODUT_TYPE = () => `/product/admin/product-type/`
const GET_PRODUCT_VARIANTS_URL = () => `/product/admin/product-variant/`
const UPDATE_PRODUCT_VARIANTS_URL = (id) =>
  `/product/admin/product-variant/${id}/`

export const getProducts = async (fields, params = {}) => {
  return API.get(`/product/admin/product/`, {
    params: params,
  })
}

export const getProductOptions = async (data) => {
  return API.options('/product/product/', data)
}

export const getProductCategories = async (fields, params = {}) => {
  return API.get(`/product/admin/category/`, {
    params: { ...params, fields: `id,slug,${fields.join(',')}` },
  })
}

export const getProductCategoryOptions = async (data) => {
  return API.options('/product/category/', data)
}

export const getProductTypes = async (data, params = {}) => {
  return API.get(`/product/admin/product-type/?fields=id,${data.join(',')}/`, {
    params,
  })
}

export const getProductTypeOptions = async (data) => {
  return API.options('/product/admin/product-type/', data)
}

export const GetProduct = (id) => {
  return API.get(GET_PRODUCT_URL(id))
}

export const UpdateProduct = (id, data) => {
  return API.put(GET_PRODUCT_URL(id), data)
}

export const GetProductVariants = (id) => {
  return API.get(GET_PRODUCT_VARIANTS_URL(), { params: { product: id } })
}

export const GetProductAvailability = (id) => {
  return API.get(GET_PRODUCT_AVAILABILITY_URL(id))
}

export const GetProductVariantChannelListing = (variant) => {
  return API.get(GET_PRODUCT_VARIANT_CHANNEL_LISTING_URL(variant))
}

export const UpdateProductAvailability = (data) => {
  return API.put(UPDATE_PRODUCT_AVAILABILITY(), data)
}

export const UpdateProductChannelListing = (data) => {
  return API.put(UPDATE_PRODUCT_CHANNEL_LISTING_URL(), data)
}

export const DeleteSingleProduct = (id) => {
  return API.delete(GET_PRODUCT_URL(id))
}

export const GetProductType = (id) => {
  return API.get(GET_PRODUT_TYPE(id))
}
export const UpdateProductType = (id, data) => {
  return API.patch(GET_PRODUT_TYPE(id), data)
}
export const CreateNewProductType = (data) => {
  return API.post(CREATE_PRODUT_TYPE(), data)
}
export const CreateProduct = (data) => {
  return API.post(CREATE_PRODUCT_URL(), data)
}

export const CreateVariantProduct = (data) => {
  return API.post(GET_PRODUCT_VARIANTS_URL(), data)
}

export const UpdateVariantProduct = (id, data) => {
  return API.put(UPDATE_PRODUCT_VARIANTS_URL(id), data)
}
export const DeleteVariantProduct = (id) => {
  return API.delete(UPDATE_PRODUCT_VARIANTS_URL(id))
}
