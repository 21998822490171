import React, { useMemo, useState } from "react";
import { AutoComplete, Input } from "antd";
import { useQuery } from "react-query";
import { GetVendors } from "services/vendors";
import { useSearchDebounce } from "utils/useDebouncedSearch";

function VendorSearch({ selectedVendor, setSelectedVendor }) {
  const [search, setSearch] = useSearchDebounce("");

  const { data } = useQuery(
    ["vendor-search", search],
    () => GetVendors({ search: search }),
    {
      enabled: Boolean(search.length > 1),
      refetchOnWindowFocus: false,
    }
  );

  const options = useMemo(() => {
    return data?.results.map((item) => ({
      value: item.name,
      label: <span>{item.name}</span>,
      entry: item,
    }));
  }, [data]);

  return (
    <AutoComplete
      allowClear
      showSearch={false}
      autoClearSearchValue
      style={{ borderRadius: 8 }}
      options={options}
      onSelect={(_, obj) => setSelectedVendor(obj.entry.id)}
      onSearch={(value) => setSearch(value)}
    >
      <Input size="large" placeholder="جست و جو در فروشگاه..." />
    </AutoComplete>
  );
}

export { VendorSearch };
