import React, { Component } from 'react'
import Mapir from 'mapir-react-component'

const Map = Mapir.setToken({
  transformRequest: (url) => {
    return {
      url: url,
      headers: {
        'x-api-key': process.env.REACT_APP_MAPIR_API_KEY,
        'Mapir-SDK': 'reactjs',
      },
    }
  },
})
export default class MapComponent extends Component {
  render() {
    return (
      <Mapir
        Map={Map}
        apiKey={process.env.REACT_APP_MAPIR_API_KEY}
        containerStyle={{
          ...this.props.containerStyle,
          width: '100%',
        }}
        {...this.props}
      >
        {this.props.children}
      </Mapir>
    )
  }
}
