import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'

import * as theme from './themeRedux/reducer'
import * as auth from './authRedux/reducer'
import * as common from './commonRedux/reducer'

export const rootReducer = combineReducers({
  auth: auth.reducer,
  theme: theme.reducer,
  common: common.reducer,
})

export function* rootSaga() {
  yield all([auth.saga()])
}
