import { Checkbox } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import styles from './styles.module.css'

const CodarxCheckBox = ({ ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <Checkbox
      style={{ color: colorPalette.text.op_10 }}
      className={styles.checkbox}
      {...props}
    >
      {props.children}
    </Checkbox>
  )
}

export default CodarxCheckBox
