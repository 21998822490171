import React from 'react'
import { CodarxLogo } from 'assets/images'
import BouncingDots from '../../bouncingDots'

import styles from './styles.module.css'

const SplashScreen = () => {
  return (
    <div className={styles.codarx_splash}>
      <img style={{ marginBottom: 92 }} src={CodarxLogo} />
      <BouncingDots />
    </div>
  )
}

export default SplashScreen
