import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import styles from "./styles.module.css";

const UserHistoryCard = ({}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  return (
    <div
      style={{ color: colorPalette.text.main }}
      className={styles.user_history_card_container}
    >
      <div className={styles.user_history_card_header}>تاریخچه کاربر</div>
      <div
        className={styles.user_history_card_layout}
        style={{ borderBottom: `1px solid ${colorPalette.text.op_9}` }}
      >
        <div className={styles.user_history_card_title}>آخرین ورود</div>
        <div className={styles.user_history_card_text}>2 ساعت پیش</div>
      </div>
      <div className={styles.user_history_card_layout}>
        <div className={styles.user_history_card_title}>آخرین سفارش</div>
        <div className={styles.user_history_card_text}>5 روز پیش</div>
      </div>
    </div>
  );
};
UserHistoryCard.prototype = {};
export default UserHistoryCard;
