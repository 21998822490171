import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, notification, Statistic } from "antd";
import { useNavigate } from "react-router-dom";

import { FormAlert } from "../alerts";
import CodarxInput from "../codarxInput";
import { CodarxBtn } from "../buttons";

import styles from "../../pages/AuthPage/styles.module.css";

import { CheckOTPCode, SendOTPCode } from "services/auth";
import { actions as authActions } from "../../store/authRedux/actions";
import { useFormik } from "formik";
import * as Yup from "yup";

const { Countdown } = Statistic;

const initialValues = {
  phone_number: "",
  code: "",
  uuid: "static-uuid",
};

const validationSchema = Yup.object({
  phone_number: Yup.string()
    .min(11, "شماره موبایل باید 11 رقم باشد")
    .max(11, "شماره موبایل باید 11 رقم باشد"),
  code: Yup.string()
    .min(4, "کد ارسال شده باید 4 رقم باشد")
    .max(4, "کد ارسال شده باید 4 رقم باشد"),
});

const LoginOTPForm = () => {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [displayCheckOTP, setDisplayCheckOTP] = useState(false);
  const [countDown, setCountDown] = useState(120);
  const [coolDown, setCoolDown] = useState(true);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) =>
      displayCheckOTP ? _checkOTP(values) : _sendOTP(values),
    validateOnChange: false,
  });

  const _sendOTP = (values) => {
    setLoading(true);
    SendOTPCode(values)
      .then((result) => {
        setLoading(false);
        setDisplayCheckOTP(true);
        setCoolDown(true);
        setCountDown(120);
        setAlert({
          type: "info",
          message: `لطفا کد ارسال شده به شماره ${values.phone_number} وارد نمایید`,
        });
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          type: "error",
          message: "کاربری با این شماره تلفن وجود ندارد!",
        });
      });
  };

  const _checkOTP = (values) => {
    setLoading(true);
    CheckOTPCode(values)
      .then((result) => {
        setLoading(false);
        if (["ADMIN", "VENDOR"].includes(result.role)) {
          const { access_token, refresh_token } = result;
          dispatch(authActions.login(access_token, refresh_token));
          notification.success({ message: "خوش آمدید!" });
        } else {
          notification.error({ message: "شما به پنل مدیریت دسترسی ندارید!" });
        }
      })
      .catch((err) => {
        setLoading(false);
        setAlert({
          type: "error",
          message:
            "لطفا کد درست را وارد کنید یا بر روی ارسال دوباره کلیک کنید.",
        });
      });
  };

  const _getCodeMessage = () => {
    if (displayCheckOTP && !coolDown) {
      return {
        type: "warning",
        rightComponent: <span>زمان به اتمام رسید.</span>,
      };
    } else if (formik.values.code.length > 0 && formik.errors.code) {
      return {
        type: "error",
        rightComponent: formik.errors.code,
      };
    } else if (displayCheckOTP && alert?.type == "error") {
      return { type: "error", rightComponent: "کد ورودی اشتباه است." };
    }
  };

  useEffect(() => {
    if (countDown == 1) {
      setCoolDown(false);
      setAlert({
        type: "warning",
        message:
          "زمان ورود کد به اتمام رسید لطفا بر روی ارسال دوباره کلیک کنید.",
      });
    }
    const timerInterval = setInterval(
      () => countDown > 0 && setCountDown(countDown - 1),
      1000
    );
    return () => clearInterval(timerInterval);
  }, [countDown]);

  return (
    <div className={styles.page_form}>
      <div className={styles.form_body}>
        <h1 className={styles.title} style={{ color: colorPalette.text.title }}>
          ورود با تلفن همراه
        </h1>
        {alert && (
          <div className={styles.form_alert}>
            <FormAlert type={alert.type} message={alert.message} />
          </div>
        )}
        <div
          style={{ display: displayCheckOTP ? "none" : "block" }}
          className={styles.form_input}
        >
          <CodarxInput
            block={true}
            type="text"
            value={formik.values.phone_number}
            onChange={(e) =>
              formik.setFieldValue(
                "phone_number",
                e.target.value.replace(/[٠-٩۰-۹]/g, (a) => a.charCodeAt(0) & 15)
              )
            }
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                formik.submitForm();
              }
            }}
            message={
              formik.errors.phone_number && {
                type: "error",
                rightComponent: formik.errors.phone_number,
              }
            }
            placeholder={"تلفن همراه"}
          />
        </div>
        <div
          style={{ display: displayCheckOTP ? "block" : "none" }}
          className={styles.form_input}
        >
          <CodarxInput
            block={true}
            type="text"
            value={formik.values.code}
            onChange={(e) => formik.setFieldValue("code", e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                formik.submitForm();
              }
            }}
            message={{
              ..._getCodeMessage(),
              leftComponent: displayCheckOTP && coolDown && (
                <span
                  style={{ color: colorPalette.text.op_3 }}
                  className={styles.cool_down_txt}
                >
                  {parseInt(countDown / 60) > 9
                    ? parseInt(countDown / 60)
                    : `0${parseInt(countDown / 60)}`}
                  :
                  {countDown - parseInt(countDown / 60) * 60 > 9
                    ? countDown - parseInt(countDown / 60) * 60
                    : `0${countDown - parseInt(countDown / 60) * 60}`}
                </span>
              ),
            }}
            placeholder={"کد"}
          />
        </div>
      </div>
      <div className={styles.form_action}>
        <div className={styles.form_button}>
          <CodarxBtn
            height={40}
            loading={loading}
            onClick={() => formik.submitForm()}
            block={true}
          >
            {displayCheckOTP ? "ورود" : "تایید"}
          </CodarxBtn>
        </div>
        <div className={styles.form_button}>
          {displayCheckOTP ? (
            <CodarxBtn
              onClick={() =>
                _sendOTP({
                  phone_number: formik.values.phone_number,
                })
              }
              block={true}
              ghost={true}
              disabled={coolDown}
            >
              ارسال دوباره
            </CodarxBtn>
          ) : (
            <CodarxBtn
              height={40}
              onClick={() => navigate("/auth/login")}
              block={true}
              ghost={true}
            >
              ورود با نام کاربری
            </CodarxBtn>
          )}
        </div>
      </div>
    </div>
  );
};

export default LoginOTPForm;
