import React, { useEffect, useState } from "react";
import { Menu, Dropdown, Radio, Checkbox } from "antd";
import { CodarxBtn, CodarxCheckBox, CodarxDatePicker } from "..";
import { Popover } from "react-tiny-popover";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { getChannels } from "services/misc";
const { SubMenu } = Menu;
const StyledSubMenu = styled(SubMenu)`
  .ant-menu-submenu-title {
    background-color: #ffffff !important;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${({ colorPalette }) => colorPalette.text.main} !important;
  }
  .ant-menu-submenu-title:hover {
    color: ${({ colorPalette }) => colorPalette.text.main} !important;
    .ant-menu-submenu-arrow::before,
    .ant-menu-submenu-arrow,
    .ant-menu-submenu-arrow::after {
      background: ${({ colorPalette }) => colorPalette.text.main} !important;
      color: ${({ colorPalette }) => colorPalette.text.main} !important;
      background-color: ${({ colorPalette }) =>
        colorPalette.text.main} !important;
    }
  }
  .ant-menu {
    background: ${({ colorPalette }) => colorPalette.primary.op_8} !important;
  }
`;
const StyledMenuItem = styled(Menu.Item)`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${({ colorPalette }) => colorPalette.text.main} !important;
  cursor: default;
`;

const CustomersFilters = ({ onChange }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const colorPalette = useSelector(({ theme }) => theme.palette);
  const [statusRadio, setStatusRadio] = useState(0);
  const [userTypeRadio, setUserTypeRadio] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [channels, setChannels] = useState([]);
  const [channelsValue, setChannelsValue] = useState(null);

  const onChangeStatusRadio = (e) => {
    setStatusRadio(e.target.value);
  };
  const onChangeUserTypeRadio = (e) => {
    setUserTypeRadio(e.target.value);
  };
  const onChangeChannels = (values) => {
    setChannelsValue(values);
  };
  const onChangeFilters = () => {
    let filters = {};
    if (statusRadio !== 0) {
      filters["is_active"] = statusRadio;
    }
    if (startDate !== "") {
      filters["start_date"] = startDate;
    }
    if (endDate !== 0) {
      filters["end_date"] = endDate;
    }
    if (userTypeRadio !== 0) {
      filters["user_type"] = userTypeRadio;
    }
    if (channelsValue !== 0) {
      filters["channels"] = channelsValue;
    }
    onChange(filters);
  };
  // useEffect(() => {
  //   if (channels.length === 0) {
  //     getChannels()
  //       .then((res) => {
  //         setChannels(res.results);
  //       })
  //       .catch((res) => {});
  //   }
  // }, []);
  const menu = (
    <Menu mode="inline">
      <StyledSubMenu
        colorPalette={colorPalette}
        key="sub1"
        title={<CodarxCheckBox>وضعیت حساب کاربری</CodarxCheckBox>}
        className={styles.customer_filter_submenu}
      >
        <Radio.Group onChange={onChangeStatusRadio} value={statusRadio}>
          <StyledMenuItem
            colorPalette={colorPalette}
            className={styles.customer_filter_menu_item}
            key="1"
            disabled
          >
            <Radio value={1}>فعال</Radio>
          </StyledMenuItem>
          <StyledMenuItem
            colorPalette={colorPalette}
            key="2"
            className={styles.customer_filter_menu_item}
            disabled
          >
            <Radio value={2}>غیر فعال</Radio>
          </StyledMenuItem>
        </Radio.Group>
      </StyledSubMenu>
      <StyledSubMenu
        colorPalette={colorPalette}
        key="sub2"
        title={<CodarxCheckBox>چنل</CodarxCheckBox>}
        className={styles.customer_filter_submenu}
      >
        <Checkbox.Group style={{ width: "100%" }} onChange={onChangeChannels}>
          {channels.map((item, index) => (
            <StyledMenuItem
              colorPalette={colorPalette}
              className={styles.customer_filter_menu_item}
              key={"channel" + index}
              disabled
            >
              <CodarxCheckBox value={item.id}>{item.name} </CodarxCheckBox>
            </StyledMenuItem>
          ))}
        </Checkbox.Group>
      </StyledSubMenu>
      <StyledSubMenu
        colorPalette={colorPalette}
        key="sub3"
        title={<CodarxCheckBox>نوع کاربر</CodarxCheckBox>}
        className={styles.customer_filter_submenu}
      >
        <Radio.Group onChange={onChangeUserTypeRadio} value={userTypeRadio}>
          <StyledMenuItem
            colorPalette={colorPalette}
            className={styles.customer_filter_menu_item}
            key="1"
            disabled
          >
            <Radio value={1}>حقیقی</Radio>
          </StyledMenuItem>
          <StyledMenuItem
            colorPalette={colorPalette}
            key="2"
            className={styles.customer_filter_menu_item}
            disabled
          >
            <Radio value={2}>حقوقی</Radio>
          </StyledMenuItem>
        </Radio.Group>
      </StyledSubMenu>
      <StyledSubMenu
        colorPalette={colorPalette}
        key="sub4"
        title={<CodarxCheckBox>تاریخ ورود</CodarxCheckBox>}
        className={styles.customer_filter_submenu}
      >
        <StyledMenuItem
          colorPalette={colorPalette}
          className={styles.customer_filter_menu_item}
          key="1"
          disabled
          style={{ height: "207px" }}
        >
          از
          <CodarxDatePicker
            onChange={(val) => {
              setStartDate(val);
            }}
            placeholder={"تاریخ"}
          />
          تا
          <CodarxDatePicker
            onChange={(val) => {
              setEndDate(val);
            }}
            placeholder={"تاریخ"}
          />
        </StyledMenuItem>
      </StyledSubMenu>
    </Menu>
  );
  return (
    <Popover
      isOpen={isPopoverOpen}
      align="end"
      positions={["bottom"]} // preferred positions by priority
      content={
        <div
          className={styles.customer_filter}
          style={{ color: colorPalette.text.main }}
        >
          <div
            className={styles.customer_filter_title}
            style={{ borderBottom: "1px solid " + colorPalette.text.op_9 }}
          >
            <div className={styles.customer_filter_text}>فیلترها</div>
            <div className={styles.customer_filter_delete}>
              <CodarxBtn
                onClick={() => {
                  setIsPopoverOpen(false);
                }}
                width="72px"
                height="32px"
                danger
                ghost={true}
              >
                پاک کردن
              </CodarxBtn>
            </div>

            <CodarxBtn
              onClick={() => {
                setIsPopoverOpen(false);
                onChangeFilters();
              }}
              width="72px"
              height="32px"
              type="primary"
            >
              تایید
            </CodarxBtn>
          </div>
          <PerfectScrollbar
            style={{
              maxHeight: "421px",
            }}
          >
            <div>{menu}</div>
          </PerfectScrollbar>
        </div>
      }
    >
      <div>
        <CodarxBtn
          width={100}
          height={40}
          ghost={true}
          onClick={() => setIsPopoverOpen(!isPopoverOpen)}
        >
          فیلترها
        </CodarxBtn>
      </div>
    </Popover>
  );
};
export default CustomersFilters;
