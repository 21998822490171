import React from 'react'
import PropTypes from 'prop-types'
import { DangerVector, WarningVector } from 'assets/images'

import styles from './styles.module.css'
import { useSelector } from 'react-redux'

export const FormMessage = ({ type, right, left }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)
  
  return (
    <div
      style={{ color: colorPalette.text.op_3 }}
      className={styles.message_box}
    >
      <div>
        <img
          style={{ height: 13, marginLeft: 3 }}
          src={type == 'error' ? DangerVector : WarningVector}
        />
        {right}
      </div>
      {left}
    </div>
  )
}

FormMessage.defaultProps = {
  type: 'error',
  right: <></>,
  left: <></>,
}

FormMessage.propTypes = {
  type: PropTypes.string,
  right: PropTypes.element,
  left: PropTypes.element,
}

export default FormMessage
