// import { AutoComplete } from 'antd'
// import React, { useEffect, useState } from 'react'
// import styled from 'styled-components'
// import API from 'utils/API'

// const CustomAutoComplete = styled(AutoComplete)`
//   border-radius: 4px;
//   text-align: right;
//   box-shadow: none;
// `

// const CodarxAutoComplete = ({ dynamic, optionsURL, ...props }) => {
// const [options, setOptions] = useState(props.options || [])

// useEffect(() => {
//   if (dynamic) {
//     API.get(optionsURL)
//       .then((result) => {
//         setOptions([result.results])
//       })
//       .catch((err) => {})
//   }
// }, [dynamic])

//   return <CustomAutoComplete options={options} {...props} />
// }

// export default CodarxAutoComplete
import { AutoComplete, Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import styles from './styles.module.css'
import { DangerVector, WarningVector } from 'assets/images'
import API from 'utils/API'

const BaseInput = styled(AutoComplete)`
  color: ${(props) => props.titleColor};
  width: 100%;
  height: 22px;
  padding: 4px 0px !important;
  text-align: right;
  direction: ltr;
  & .pass_toggle {
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: calc(50% - 10px);
  }
  & .ant-select-selection-search {
    width: 100%;
    padding-right: 10px;
  }
  & .ant-select-selector {
    width: 100%;
    padding: 0px !important;
  }
  & .ant-select {
    width: 100%;
  }
  & input {
    text-align: right;
  }
`

const { Option } = AutoComplete

const CodarxAutoComplete = ({
  width,
  height,
  placeholder,
  message,
  type,
  bgContainer,
  dynamic,
  optionsURL,
  onSelect,
  onChange,
  ...props
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const [focused, setFocused] = useState(false)
  const [secured, setSecured] = useState(true)
  const [value, setValue] = useState()
  const [options, setOptions] = useState(props.options || [])

  const InputRef = useRef()

  const [isOccupied, setIsOccupied] = useState(focused || props.value)

  const _getBaseColor = () => {
    if (message) {
      switch (message.type) {
        case 'info':
          return colorPalette.primary.op_10
        case 'warning':
          return colorPalette.warning.op_4
        case 'error':
          return colorPalette.danger.op_1

        default:
          return colorPalette.primary.op_10
      }
    } else {
      return isOccupied ? colorPalette.primary.op_10 : colorPalette.text.op_5
    }
  }

  useEffect(() => {
    setIsOccupied(focused || props.value)
  }, [props.value, focused])

  const _getOptions = (val = '') => {
    API.get(optionsURL, { params: { search: val } })
      .then((result) => {
        setOptions(
          result.results.map((item) => ({ key: item.id, value: item.name })),
        )
      })
      .catch((err) => {})
  }

  useEffect(() => {
    if (dynamic) {
      _getOptions()
    }
  }, [dynamic])

  return (
    <div
      onClick={() => InputRef.current.focus()}
      className={`${styles.content_container} codarx_input`}
    >
      <div
        style={{
          width,
          height,
          border: `1px solid ${_getBaseColor()}`,
          background: bgContainer,
        }}
        className={styles.input_container}
      >
        <BaseInput
          ref={InputRef}
          dir="rtl"
          options={options}
          type={type == 'password' ? (secured ? 'password' : 'text') : type}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          bordered={false}
          titleColor={colorPalette.text.title}
          onSearch={(val) => dynamic && _getOptions(val)}
          onSelect={(val, option) => {
            setValue(option)
            onSelect(option)
          }}
          onChange={(val) => {
            setValue(val)
            onChange(val)
          }}
          {...props}
        />
        <span
          style={{
            color: isOccupied ? _getBaseColor() : colorPalette.text.op_3,
            fontSize: isOccupied ? 10 : 14,
            top: isOccupied ? 4 : 'calc(50% - 11px)',
          }}
          className={styles.placeholder}
        >
          {placeholder}
        </span>
        {type == 'password' && (
          <i
            style={{ color: colorPalette.text.op_3 }}
            onClick={() => setSecured(!secured)}
            className={`${styles.pass_toggle} ${
              secured ? 'iconly-Hide' : 'iconly-Show'
            }`}
          ></i>
        )}
      </div>
      {message && (
        <div
          style={{ color: colorPalette.text.op_3 }}
          className={styles.message_box}
        >
          {message.rightComponent ? (
            <div>
              <img
                style={{ height: 13, marginLeft: 3 }}
                src={message.type == 'error' ? DangerVector : WarningVector}
              />
              {message.rightComponent}
            </div>
          ) : (
            <span></span>
          )}
          {message.leftComponent ? message.leftComponent : <span></span>}
        </div>
      )}
    </div>
  )
}

CodarxAutoComplete.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  message: PropTypes.object,
  ...Input.propTypes,
}

CodarxAutoComplete.defaultProps = {
  placeholder: '',
  height: 52,
  bgContainer: 'transparent',
  onSelect: () => {},
  onChange: () => {},
}

export default CodarxAutoComplete
