import { Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import styles from './styles.module.css'
import { DangerVector, WarningVector } from 'assets/images'

const BaseInput = styled(Input.TextArea)`
  color: ${(props) => props.titleColor};
  width: 100%;
  padding: 4px 0px !important;
  text-align: right;
  margin-top: 8px;
  resize: none;
  & .pass_toggle {
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: calc(50% - 10px);
  }
`

const CodarxTextArea = ({
  width,
  height,
  placeholder,
  type,
  numberOfLines,
  ...props
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const [focused, setFocused] = useState(false)
  const [secured, setSecured] = useState(true)

  const InputRef = useRef()

  const [isOccupied, setIsOccupied] = useState(
    focused || InputRef.current?.resizableTextArea.textArea.value,
  )

  const _getBaseColor = () => {
    return isOccupied ? colorPalette.primary.op_10 : colorPalette.text.op_5
  }

  useEffect(() => {
    setIsOccupied(focused || InputRef.current?.resizableTextArea.textArea.value)
  }, [props.value, focused])

  return (
    <div className={`${styles.content_container} codarx_input`}>
      <div
        onClick={() => InputRef.current.focus()}
        style={{
          width,
          height,
          border: `1px solid ${_getBaseColor()}`,
        }}
        className={styles.input_container}
      >
        <BaseInput
          ref={InputRef}
          type={type == 'password' ? (secured ? 'password' : 'text') : type}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          bordered={false}
          titleColor={colorPalette.text.title}
          rows={numberOfLines}
          {...props}
        />
        <span
          style={{
            color: isOccupied ? _getBaseColor() : colorPalette.text.op_3,
            fontSize: isOccupied ? 10 : 14,
            top: isOccupied ? 4 : '11px',
          }}
          className={styles.placeholder}
        >
          {placeholder}
        </span>
        {type == 'password' && (
          <i
            style={{ color: colorPalette.text.op_3 }}
            onClick={() => setSecured(!secured)}
            className={`${styles.pass_toggle} ${
              secured ? 'iconly-Hide' : 'iconly-Show'
            }`}
          ></i>
        )}
      </div>
    </div>
  )
}

CodarxTextArea.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  message: PropTypes.object,
  ...Input.propTypes,
}

CodarxTextArea.defaultProps = {
  placeholder: '',
  height: 203,
}

export default CodarxTextArea
