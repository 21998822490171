import { Collapse } from 'antd'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import styles from './styles.module.css'

const { Panel } = Collapse

const CustomPanel = styled(Panel)`
  & .ant-collapse-header {
    padding: 24px !important;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px !important;
    color: ${({ colorPalette }) => colorPalette.text.main} !important;
    background: white;
    border-top: 1px solid ${({ colorPalette }) => colorPalette.text.op_8};
    border-bottom: 1px solid ${({ colorPalette }) => colorPalette.text.op_8};
  }
  & .ant-collapse-content-box {
    background: white;
    padding: 0 !important;
  }
`

const CodarxCollapse = ({ title, content, ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <div className={styles.container}>
      <Collapse {...props} bordered={false}>
        <CustomPanel
          showArrow={false}
          colorPalette={colorPalette}
          header={
            <span className={styles.title}>
              {title} <i className={'iconly-Arrow-Down-2'}></i>
            </span>
          }
        >
          <div className={styles.content}>{content}</div>
        </CustomPanel>
      </Collapse>
    </div>
  )
}

export default CodarxCollapse
