import React, { Component } from "react";
import { Popover } from "react-tiny-popover";
import styles from "./styles.module.css";
import onClickOutside from "react-onclickoutside";
import "react-perfect-scrollbar/dist/css/styles.css";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";
import { CodarxCheckBox } from "components";
import { Checkbox } from "antd";
const DropDownContainer = styled.div`
  border: ${({ borderColor }) => "1px solid " + borderColor};
  border-radius: 4px;
  z-index: ${({ isPopoverOpen }) => (isPopoverOpen ? 3 : 2)};
  position: absolute;
  width: 100%;
  background-color: #fff;
  &:hover {
    border: ${({ borderHoverColor }) => "1px solid " + borderHoverColor};
  }
`;

class StaffsSelectBox extends Component {
  constructor(props) {
    super(props);
    this.state = { isPopoverOpen: false, selected: [], all: false };
  }
  handleClickOutside = (evt) => {
    this.setState({ isPopoverOpen: false });
  };
  componentDidMount() {}
  onChangeAll = (e) => {
    this.setState({ all: e.target.checked });
  };
  onChange = (checkedValues) => {
    this.setState({ selected: checkedValues });
  };
  render() {
    return (
      <div
        className={styles.dropdown}
        style={{
          color: this.props.textColor,
          width: this.props.width,
          height: this.props.height,
        }}
      >
        <DropDownContainer
          defaultValue={this.props.defaultValue}
          onChange={(e) => this.props.onChange(e)}
          isPopoverOpen={this.state.isPopoverOpen}
          borderColor={this.props.borderColor}
          borderHoverColor={this.props.borderHoverColor}
        >
          <div
            style={{
              color: this.props.textColor,
              width: this.props.width,
              height: this.props.height,
            }}
            className={styles.dropdown_container}
            onClick={() => {
              this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
            }}
          >
            <div className={styles.dropdown_text}>{this.props.placeholder}</div>

            <i
              className={
                this.state.isPopoverOpen
                  ? "iconly-Arrow-Up-2"
                  : "iconly-Arrow-Down-2"
              }
              style={{ color: this.props.textColor }}
            ></i>
          </div>

          <div
            style={{
              display: this.state.isPopoverOpen ? "" : "none",
            }}
            className={"ignore-react-onclickoutside " + styles.dropdown_layout}
          >
            <div
              style={{
                borderBottom: "1px solid " + this.props.borderColor,
                paddingBottom: "12px",
                marginBottom: "12px",
              }}
            >
              <div
                className={"ignore-react-onclickoutside " + styles.dropdown_row}
                style={{
                  height: this.props.rowHeight,
                  backgroundColor: this.state.all
                    ? this.props.activeBgColor
                    : "",
                }}
              >
                <CodarxCheckBox onChange={this.onChangeAll}>
                  همه دسترسی ها
                </CodarxCheckBox>
              </div>
            </div>
            <PerfectScrollbar
              style={{
                height: "180px",
              }}
            >
              <div className={styles.dropdown_rtl}>
                <Checkbox.Group
                  style={{
                    width: "100%",
                  }}
                  onChange={this.onChange}
                >
                  {this.props.options.map((item, index) => (
                    <div
                      key={index}
                      className={styles.dropdown_row}
                      style={{
                        color: this.state.selected.includes(item.key)
                          ? this.props.activeColor
                          : this.props.textColor,
                        height: this.props.rowHeight,
                        backgroundColor: this.state.selected.includes(item.key)
                          ? this.props.activeBgColor
                          : "",
                      }}
                    >
                      <CodarxCheckBox value={item.key}>
                        {item.value}
                      </CodarxCheckBox>
                    </div>
                  ))}
                </Checkbox.Group>
              </div>
            </PerfectScrollbar>
            <div className={styles.dropdown_footer}></div>
          </div>
        </DropDownContainer>
      </div>
    );
  }
}

StaffsSelectBox.defaultProps = {
  positions: ["bottom"],
  width: "100%",
  height: "40px",
  maxHeight: 188,
  popoverHeight: "100%",
  rowHeight: "40px",
  options: [
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 1", value: "مقدار 1" },
    { key: "مقدار 2", value: "مقدار 2" },
  ],
  onChange: () => {},
};
export default onClickOutside(StaffsSelectBox);
