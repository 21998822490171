import { Timeline } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { _getPersianDate } from 'utils/_helpers'
import styles from './styles.module.css'

const _getStatusColor = (colorPalette, _status) => {
  switch (_status) {
    case 'success':
      return colorPalette.success.op_4
    case 'info':
      return colorPalette.primary.op_11
    case 'warning':
      return colorPalette.warning.main
    case 'danger':
      return colorPalette.danger.main
  }
}

const TimeLineItem = styled(Timeline.Item)`
  height: 74px;
  & .ant-timeline-item-tail {
    border-color: ${({ colorPalette }) => colorPalette.primary.main};
    border-style: dashed;
    border-width: 1px;
  }
  & .ant-timeline-item-head {
    width: ${({ biggest }) => (biggest ? '13px' : '9px')};
    height: ${({ biggest }) => (biggest ? '13px' : '9px')};
    right: ${({ biggest }) => (biggest ? '-2px' : '0px')};
    background: ${({ colorPalette, status }) =>
      _getStatusColor(colorPalette, status)};
    border: none;
  }
  & .ant-timeline-item-content {
    margin-right: 21px;
  }
`

const CodarxTimeLine = ({ events, ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <Timeline>
      {events.map((event, i) => {
        return (
          <TimeLineItem
            key={'event' + i}
            biggest={events.length - 1 == i}
            status={event.type}
            colorPalette={colorPalette}
          >
            <div className={'flex-row-between'}>
              <h5
                style={{ color: colorPalette.text.main }}
                className={styles.tTitle}
              >
                {event.text}
              </h5>
              <span
                style={{ color: colorPalette.text.main }}
                className={styles.tDate}
              >
                {event.created_date.split(' ')[1]}{' '}
                {_getPersianDate(new Date(event.created_date))}
              </span>
            </div>
          </TimeLineItem>
        )
      })}
    </Timeline>
  )
}

export default CodarxTimeLine
