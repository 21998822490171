import { Col, Row, Form } from "antd";
import React, { useEffect, useState } from "react";
import { CodarxBtn, CodarxInput, CodarxModal, Title } from "..";
import { noFileImage } from "assets/images";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { CreateNewFile } from "services/file";
import { useMediaQuery } from "utils/useMediaQuery";

const CategoriesImageModal = ({
  visible,
  setVisible,
  setImageFileData,
  imageFileData,
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette);
  const [form, setForm] = useState(new FormData());
  const [image, setImage] = useState(null);
  const [text, setText] = useState("");
  const isTabletOrMobile = useMediaQuery("(max-width:992px)");

  const onImageChange = (event) => {
    let image = event.target.files;
    if (image && image[0]) {
      let fd = new FormData();
      fd.append("file", image[0]);
      setForm(fd);
      setImage(URL.createObjectURL(image[0]));
    }
  };
  useEffect(() => {
    if (imageFileData !== null) {
      setText(imageFileData.alt);
      setImage(imageFileData.file);
    }
  }, [imageFileData]);

  return isTabletOrMobile ? (
    <CodarxModal
      onCancel={() => setVisible(false)}
      onOk={() => {
        if (image !== null) {
          form.set("alt", text);
          CreateNewFile(form)
            .then((res) => {
              setImageFileData(res);
              setVisible(false);
            })
            .catch((res) => {});
        } else {
          setImageFileData(null);
          setVisible(false);
        }
      }}
      visible={visible}
      okText={"ذخیره"}
      cancelText={"برگشت"}
      width={"90%"}
      style={{ maxWidth: "343px", top: "40px" }}
      isMobile={true}
      footer={null}
    >
      <div
        className={styles.category_modal_container_m}
        style={{ borderBottom: "1px solid " + colorPalette.text.op_9 }}
      >
        <Row>
          <Col span={24}>
            <div
              className={styles.category_modal_img_container_m}
              style={{ border: "1px solid " + colorPalette.text.op_9 }}
            >
              <img
                src={image === null ? noFileImage : image}
                alt=""
                className={styles.category_modal_img}
                width={image === null ? "200px" : "311px"}
                height={image === null ? "200px" : "311px"}
              />
            </div>
          </Col>
          <Col span={24}>
            <Title text="ویرایش عکس" />
            <div
              className={styles.category_modal_img_info}
              style={{
                backgroundColor: colorPalette.primary.op_9,
                color: colorPalette.text.main,
              }}
            >
              <div className={styles.category_modal_img_date}>
                تاریخ بارگذاری :
                {imageFileData === null
                  ? " --/--/---- "
                  : " " +
                    new Date(
                      imageFileData.created_date.split(" ")[0]
                    ).toLocaleDateString("fa-IR")}
              </div>
              <div className={styles.category_modal_img_info_text}>
                {imageFileData === null
                  ? " -- "
                  : imageFileData.image_dimensions.width +
                    "*" +
                    imageFileData.image_dimensions.height +
                    "   px "}
                : اندازه عکس
              </div>
              <div className={styles.category_modal_img_info_text}>
                {imageFileData === null
                  ? " -- "
                  : " " + imageFileData.size + " kb "}
                : حجم عکس
              </div>
            </div>

            <div>
              <CodarxInput
                placeholder={"متن جایگزین"}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>

            <div className={styles.category_modal_btns_m}>
              <div className={styles.category_modal_btn_m}>
                <CodarxBtn
                  height="40"
                  onClick={() => {
                    document.getElementById("selectedFile").click();
                  }}
                >
                  انتخاب عکس
                  <input
                    onChange={onImageChange}
                    type="file"
                    accept="image/*"
                    id="selectedFile"
                    className={styles.category_modal_fileinput}
                  />
                </CodarxBtn>
              </div>
              <div className={styles.category_modal_btn_m}>
                <CodarxBtn
                  height="40"
                  danger
                  ghost={true}
                  disabled={image === null ? true : false}
                  onClick={() => {
                    setImage(null);
                    setForm(new FormData());
                  }}
                >
                  حذف عکس
                </CodarxBtn>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div className={styles.category_modal_footer_btns_m}>
        <div className={styles.category_modal_btn_m}>
          <CodarxBtn
            height="40"
            onClick={() => {
              if (image !== null) {
                form.set("alt", text);
                CreateNewFile(form)
                  .then((res) => {
                    setImageFileData(res);
                    setVisible(false);
                  })
                  .catch((res) => {});
              } else {
                setImageFileData(null);
                setVisible(false);
              }
            }}
          >
            ذخیره
          </CodarxBtn>
        </div>
        <div
          className={styles.category_modal_btn_m}
          style={{ marginBottom: "0px" }}
        >
          <CodarxBtn height="40" ghost={true} onClick={() => setVisible(false)}>
            برگشت
          </CodarxBtn>
        </div>
      </div>
    </CodarxModal>
  ) : (
    <CodarxModal
      onCancel={() => setVisible(false)}
      onOk={() => {
        if (image !== null) {
          form.set("alt", text);
          CreateNewFile(form)
            .then((res) => {
              setImageFileData(res);
              setVisible(false);
            })
            .catch((res) => {});
        } else {
          setImageFileData(null);
          setVisible(false);
        }
      }}
      visible={visible}
      okText={"ذخیره"}
      cancelText={"برگشت"}
      width={"90%"}
      style={{ maxWidth: "1080px", top: "40px" }}
    >
      <div
        className={styles.category_modal_container}
        style={{ borderBottom: "1px solid " + colorPalette.text.op_9 }}
      >
        <Row>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <div
              className={styles.category_modal_img_container}
              style={{ border: "1px solid " + colorPalette.text.op_9 }}
            >
              <img
                src={image === null ? noFileImage : image}
                alt=""
                className={styles.category_modal_img}
                width={image === null ? "240px" : "100%"}
                height={image === null ? "240px" : "500px"}
              />
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 12 }}>
            <Title text="ویرایش عکس" />
            <div
              className={styles.category_modal_img_info}
              style={{
                backgroundColor: colorPalette.primary.op_9,
                color: colorPalette.text.main,
              }}
            >
              <div className={styles.category_modal_img_date}>
                تاریخ بارگذاری :
                {imageFileData === null
                  ? " --/--/---- "
                  : " " +
                    new Date(
                      imageFileData.created_date.split(" ")[0]
                    ).toLocaleDateString("fa-IR")}
              </div>
              <div className={styles.category_modal_img_info_text}>
                {imageFileData === null
                  ? " -- "
                  : imageFileData.image_dimensions.width +
                    "*" +
                    imageFileData.image_dimensions.height +
                    "   px "}
                : اندازه عکس
              </div>
              <div className={styles.category_modal_img_info_text}>
                {imageFileData === null
                  ? " -- "
                  : " " + imageFileData.size + " kb "}
                : حجم عکس
              </div>
            </div>

            <div>
              <CodarxInput
                placeholder={"متن جایگزین"}
                value={text}
                onChange={(e) => {
                  setText(e.target.value);
                }}
              />
            </div>

            <div className={styles.category_modal_btns}>
              <div className={styles.category_modal_btn}>
                <CodarxBtn
                  width="160"
                  height="40"
                  onClick={() => {
                    document.getElementById("selectedFile").click();
                  }}
                >
                  انتخاب عکس
                  <input
                    onChange={onImageChange}
                    type="file"
                    accept="image/*"
                    id="selectedFile"
                    className={styles.category_modal_fileinput}
                  />
                </CodarxBtn>
              </div>
              <CodarxBtn
                width="160"
                height="40"
                danger
                ghost={true}
                disabled={image === null ? true : false}
                onClick={() => {
                  setImage(null);
                  setForm(new FormData());
                }}
              >
                حذف عکس
              </CodarxBtn>
            </div>
          </Col>
        </Row>
      </div>
    </CodarxModal>
  );
};
export default CategoriesImageModal;

CategoriesImageModal.defaultProps = {
  fileData: null,
};
