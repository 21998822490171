import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { CustomerSearch, ProductsSearch, VendorSearch } from "./partials";
import { STATUS_DICTIONARY } from "constants";
import { Button, DatePicker, Select } from "antd";
import moment from "moment";

const { RangePicker } = DatePicker;

const ISACTIVE_OPTIONS = [
  { label: "موجود", value: true },
  { label: "ناموجود", value: false },
];

function VendorProductsFilters() {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  const [isAvailable, setIsAvailable] = useState(null);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [selectedProductCategories, setSelectedProductCategories] = useState(null);

  const { state } = useLocation();
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("", {
      state: {
        ...state,
        vendor: selectedVendor,
        product__category: selectedProductCategories,
        is_available: isAvailable,
      },
    });
  };

  const handleClearFilters = () => {
    navigate("", {
      state: {
        page: state?.page,
        search: state?.search,
        page_size: state?.page_size,
      },
    });
    setSelectedProductCategories(null);
    setSelectedVendor(null);
    setIsAvailable(null);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="pr_table_header"
      style={{
        borderTop: "1px solid " + colorPalette.text.op_8,
        borderBottom: "1px solid " + colorPalette.text.op_8,
        display: "flex",
        gap: "0.5rem",
      }}
    >
      <ProductsSearch
        selectedProductCategories={selectedProductCategories}
        setSelectedProductCategories={setSelectedProductCategories}
      />
      <VendorSearch
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
      />
      <Select
        value={isAvailable}
        placeholder="وضعیت"
        defaultActiveFirstOption={false}
        showArrow={false}
        filterOption={false}
        onChange={(val) => setIsAvailable(val)}
        style={{
          borderRadius: 8,
          width: "200px",
        }}
        bordered={false}
        options={ISACTIVE_OPTIONS}
      />
      <Button
        htmlType="submit"
        type="primary"
        style={{ marginRight: "auto", borderRadius: 4 }}
      >
        اعمال فیلترها
      </Button>
      <Button type="text" danger onClick={handleClearFilters}>
        حذف تمامی فیلتر ها
      </Button>
    </form>
  );
}

export default VendorProductsFilters;
