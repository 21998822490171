const colorPalette = {
  light: {
    text: {
      main: "#505151",
      title: "#0C0B0B",
      op_1: "#1D1F1F",
      op_2: "#353737",
      op_3: "#626465",
      op_4: "#8A9DF0",
      op_5: "#808080",
      op_6: "#A1A3A5",
      op_7: "#BEBFC1",
      op_8: "#CACCCF",
      op_9: "#E2E3E4",
      op_10: "#505151",
      op_11: "#525252",
    },
    primary: {
      main: "#526FE5",
      op_1: "#2445CD",
      op_2: "#4263EB",
      op_3: "#4263EB",
      op_4: "#8A9DF0",
      op_5: "#ACBBF3",
      op_6: "#D0D8F5",
      op_7: "#E3E7F7",
      op_8: "#F1F4FE",
      op_9: "#F6F8FF",
      op_10: "#6680EE",
      op_11: "#71A3B8",
    },
    success: {
      main: "#08F956",
      op_1: "#32FF74",
      op_2: "#62FF95",
      op_3: "#B5FFCD",
      op_4: "#23C577",
    },
    danger: {
      main: "#FF3C3C",
      op_1: "#FF5A5A",
      op_2: "#FF9898",
      op_3: "#FFBBBB",
      op_4: "#FCD0D3",
      op_5: "#FFE4E4",
    },
    warning: {
      main: "#FFC521",
      op_1: "#FFCA33",
      op_2: "#FFD251",
      op_3: "#FFE6A3",
      op_4: "#FFCE42",
      op_5: "#FFF2CF",
    },
  },
  //   todo: Change palette for dark version
  dark: {
    text: {
      main: "#505151",
      title: "#0C0B0B",
      op_1: "#1D1F1F",
      op_2: "#353737",
      op_3: "#626465",
      op_4: "#8A9DF0",
      op_5: "#808080",
      op_6: "#A1A3A5",
      op_7: "#BEBFC1",
      op_8: "#CACCCF",
      op_9: "#E2E3E4",
    },
    primary: {
      main: "#526FE5",
      op_1: "#2445CD",
      op_2: "#4263EB",
      op_3: "#4263EB",
      op_4: "#8A9DF0",
      op_5: "#ACBBF3",
      op_6: "#D0D8F5",
      op_7: "#E3E7F7",
      op_8: "#F1F4FE",
      op_9: "#F6F8FF",
    },
    success: {
      main: "#08F956",
      op_1: "#32FF74",
      op_2: "#62FF95",
      op_3: "#B5FFCD",
    },
    danger: {
      main: "#FF3C3C",
      op_1: "#FF5A5A",
      op_2: "#FF9898",
      op_3: "#FFBBBB",
    },
    warning: {
      main: "#FFC521",
      op_1: "#FFCA33",
      op_2: "#FFD251",
      op_3: "#FFE6A3",
    },
  },
};

export default colorPalette;
