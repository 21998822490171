import React, { useEffect, useState } from "react";
import { CodarxDropDown, UnstyledDropDown, IconButton } from "components";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { actions } from "../../store/authRedux/actions";
import HeaderStyles from "./style";
import { getChannels } from "services/misc";
import { channelsParser } from "utils/_helpers";
import { LogoutBlacklist } from "services/auth";
import { Col, Drawer, Menu, Row } from "antd";
import { MenuBarsSvg, SampleAvatar } from "assets/images";
import { useMediaQuery } from "utils/useMediaQuery";
import NavMenu from "../navigation/NavMenu";
import NavItems from "../navigation/NavItems";

const HeaderContent = () => {
  const [channels, setChannels] = useState([]);
  const [visible, setVisible] = useState(false);
  const { avatar, full_name } = useSelector(
    ({ auth }) => auth.user,
    shallowEqual
  );
  const { refreshToken } = useSelector(
    ({ auth }) => ({
      refreshToken: auth.refresh,
    }),
    shallowEqual
  );
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isTabletOrMobile = useMediaQuery("(max-width: 992px)");

  // useEffect(() => {
  //   getChannels().then(({ results }) => setChannels(results))
  // }, [])

  const dropdownOptions = [
    {
      key: 1,
      label: "تنظیمات حساب",
      function: () => {
        navigate("/settings");
      },
    },
    {
      key: 2,
      label: "خروج از حساب",
      function: () => {
        LogoutBlacklist(refreshToken);
        dispatch(actions.logout());
      },
    },
  ];

  useEffect(() => {
    setVisible(false);
  }, [location.pathname]);

  return (
    <HeaderStyles>
      <Row>
        <Col xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
          <div className="header_content_right">
            {!isTabletOrMobile && <span>کانال: </span>}
            <CodarxDropDown
              options={channelsParser(channels)}
              currentValue={channelsParser(channels).filter(
                ({ id }) => id === 0
              )}
              type="conatained"
              fullWidth={isTabletOrMobile ? true : false}
            />
          </div>
        </Col>
        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
          <div className="header_content_left">
            {isTabletOrMobile && (
              <IconButton
                style={{ border: "1px solid #526FE5" }}
                onClick={() => setVisible(true)}
                icon={<img src={MenuBarsSvg} />}
              />
            )}
            <UnstyledDropDown
              childeren={
                <span className="ant-dropdown-link">
                  {!isTabletOrMobile && <span>{full_name}</span>}
                  <span className="avatar-wrapper">
                    <img src={avatar || SampleAvatar} alt="" />
                  </span>
                </span>
              }
              options={dropdownOptions}
            />
          </div>
        </Col>
      </Row>
      <Drawer
        placement="right"
        closable={true}
        onClose={() => setVisible(false)}
        visible={visible}
      >
        <NavMenu Items={NavItems} />
      </Drawer>
    </HeaderStyles>
  );
};

export default HeaderContent;
