import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import { Menu, Dropdown, Select, message, notification } from "antd";
import { addressCardMenuIcon } from "assets/images";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { UnstyledDropDown } from "../../codarxDropDown";
import AddressAction from "./AddressAction";
import { DeleteAddressCustomer } from "services/customer";
import { IsUserPermitted } from "utils/_helpers";

const AddressCard = ({
  index,
  address_name,
  address,
  latitude,
  longitude,
  postal_code,
  plate,
  unit,
  phone,
  user,
  onChange,
  id,
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  const [editAddressModal, setEditAddressModal] = useState(false);

  const dropdownOptions = [
    {
      key: 1,
      label: "مشاهده و ویرایش",
      function: () =>
        (IsUserPermitted("address", "change") ||
          IsUserPermitted("address", "view")) &&
        setEditAddressModal(true),
    },
    {
      key: 2,
      label: "حذف",
      function: () => IsUserPermitted("address", "delete") && _deleteAddress(),
    },
  ];

  const _deleteAddress = () => {
    const key = "delete_address_updatable";
    DeleteAddressCustomer(id, user)
      .then((result) => {
        message.success({ content: "اطلاعات با موفقیت ثبت شد", key });
        onChange();
      })
      .catch((error) => {});
  };

  return (
    <div className={styles.address_card_container}>
      <AddressAction
        user={user}
        address_id={id}
        visible={editAddressModal}
        type="edit"
        onFinish={() => onChange()}
        onChangeVisible={(val) => setEditAddressModal(val)}
        title={"ویرایش آدرس"}
        initialValues={{
          address_name,
          address,
          coordinates: [longitude, latitude],
          postal_code,
          plate,
          unit,
          phone,
        }}
      />
      <div className={styles.address_card_header}>
        <div className={styles.address_card_title}>
          آدرس
          {index}
        </div>
        <UnstyledDropDown
          childeren={
            <span className="ant-dropdown-link">
              <div
                className={styles.address_card_menu}
                style={{ background: colorPalette.primary.op_8 }}
              >
                <img
                  alt=""
                  src={addressCardMenuIcon}
                  width="4px"
                  height="18px"
                />
              </div>
            </span>
          }
          options={dropdownOptions}
        />
      </div>
      <div style={{ color: colorPalette.text.op_2 }}>
        <div className={styles.address_card_name}>{address_name}</div>
        <div className={styles.address_card_address}>{address}</div>
        <div className={styles.address_card_info}>کد پستی: {postal_code}</div>
        <div className={styles.address_card_info}>شماره تلفن: {phone}</div>
      </div>
    </div>
  );
};
AddressCard.prototype = {
  index: PropTypes.number,
};
export default AddressCard;
