export const actionTypes = {
  SET_THEME: '[Set Theme] Action',
}

export const actions = {
  themeChanged: (type) => ({
    type: actionTypes.SET_THEME,
    payload: type,
  }),
}
