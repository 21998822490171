import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { actionTypes } from './actions'

const initialCommonState = {
  channels: [],
  warehouses: [],
}

export const reducer = persistReducer(
  {
    storage,
    key: 'panel-common-variables',
    whitelist: ['channels'],
  },
  (state = initialCommonState, action) => {
    switch (action.type) {
      case actionTypes.SET_CHANNELS:
        return {
          ...state,
          channels: action.payload,
        }
      case actionTypes.SET_WAREHOUSES:
        return {
          ...state,
          warehouses: action.payload,
        }

      default:
        return state
    }
  },
)
