import { Divider } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'
import { _getPersianDate } from 'utils/_helpers'
import styles from './styles.module.css'

const CodarxNote = ({ author, date, note, isPrivate, ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <div
      style={{ background: colorPalette.primary.op_8 }}
      className={styles.container}
    >
      {isPrivate && (
        <div style={{ width: '100%' }}>
          <div
            style={{
              color: colorPalette.text.main,
              background: colorPalette.warning.op_5,
            }}
            className={styles.pv_badge}
          >
            محرمانه
          </div>
        </div>
      )}
      <div
        style={{ color: colorPalette.text.main }}
        className={`${styles.content_container} flex-row-between`}
      >
        <span>{author}</span>
        <span>
          {date.split(' ')[1]} {_getPersianDate(new Date(date))}
        </span>
      </div>
      <Divider style={{ borderColor: colorPalette.text.op_9, margin: 0 }} />
      <div style={{ padding: 12 }}>
        <span
          style={{ color: colorPalette.text.main }}
          className={styles.note_content}
        >
          {note}
        </span>
      </div>
    </div>
  )
}

export default CodarxNote
