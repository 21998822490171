import React, { useMemo, useState } from "react";
import { AutoComplete, Input } from "antd";
import { useQuery } from "react-query";
import { getUsers } from "services/user";
import { useSearchDebounce } from "utils/useDebouncedSearch";
import { getProductCategories } from "services/product";

function ProductsSearch({ selectedProductCategories, setSelectedProductCategories }) {
  const [search, setSearch] = useSearchDebounce("");

  const { data } = useQuery(
    ["product-categories-search", search],
    () => getProductCategories(['name'], { search: search }),
    {
      enabled: Boolean(search.length > 1),
      refetchOnWindowFocus: false,
    }
  );

  const options = useMemo(() => {
    return data?.results.map((item) => ({
      value: item.name,
      label: <span>{item.name}</span>,
      entry: item,
    }));
  }, [data]);

  return (
    <AutoComplete
      allowClear
      showSearch={false}
      autoClearSearchValue
      options={options}
      style={{
        borderRadius: 8,
      }}
      onSelect={(_, obj) => setSelectedProductCategories(obj.entry.id)}
      onSearch={(value) => setSearch(value)}
    >
      <Input size="large" placeholder="جست و جو در دسته بندی محصولات..." />
    </AutoComplete>
  );
}

export { ProductsSearch };
