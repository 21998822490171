import React, { memo } from "react";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { CodarxBtn, CodarxCheckBox, IconButton } from "..";
import styled from "styled-components";
import { Table } from "antd";

const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: ${({ colorPalette }) => colorPalette.text.op_5};
    background-color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    border-bottom: 1px solid ${({ colorPalette }) => colorPalette.text.op_8};
    height: 64px;
    padding: 24px 26px 15px 26px;
  }
  .ant-table-tbody > tr > td {
    height: 85px;
    padding: 0px 26px;
    color: ${({ colorPalette }) => colorPalette.text.main};
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }

  .ant-table-cell::before {
    display: none;
  }
  .table-active-row {
    background-color: ${({ colorPalette }) => colorPalette.primary.op_9};
  }
`;
const columns = [
  {
    title: "ویژگی",
    dataIndex: "subcategory",
    key: "subcategory",
  },
  {
    title: "مقدار",
    dataIndex: "no_subcategory",
    key: "no_subcategory",
  },
  {
    title: "استفاده به عنوان متغیر",
    key: "no_products",
    dataIndex: "no_products",
    align: "center",
    render: (text, record) => {
      return <CodarxCheckBox />;
    },
  },
  {
    title: "",
    dataIndex: "edit",
    key: "edit",
    width: "170px",
    render: (text, record) => {
      return (
        <div className={styles.properties_table_icons}>
          <IconButton
            style={{ margin: "0px 8px" }}
            icon={
              <i
                className={`${styles.properties_table_icon}  ${"iconly-Edit"}`}
              ></i>
            }
          />
          <IconButton
            type="danger"
            style={{ margin: "0px 8px" }}
            icon={
              <i
                className={`${
                  styles.properties_table_icon
                }  ${"iconly-Delete"}`}
              ></i>
            }
          />
        </div>
      );
    },
  },
];

const data = [
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "2",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
  {
    subcategory: "نام محصول",
    no_subcategory: "5",
    no_products: "200",
    edit: "1",
  },
];

const ProductPropertiesTable = () => {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  return (
    <div className={styles.properties_table}>
      <div className={styles.properties_table_header}>
        <h1
          className={styles.properties_table_title}
          style={{ color: colorPalette.text.main }}
        >
          ویژگی ها
        </h1>
        <CodarxBtn ghost={true} width={160} height={40}>
          افزودن ویژگی
        </CodarxBtn>
      </div>

      <div>
        <StyledTable
          columns={columns}
          dataSource={data}
          pagination={false}
          colorPalette={colorPalette}
          scroll={{ x: '100%' }}
          rowClassName={(record) =>
            record.edit === "2" ? "table-active-row" : ""
          }
        />
      </div>
    </div>
  );
};
export default memo(ProductPropertiesTable);
