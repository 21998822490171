import React from "react";
import { useSelector } from "react-redux";
import styles from "./styles.module.css";

const DashboardCard = ({ title, icon, value, quantity }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  return (
    <div className={`${styles.card_container} flex-row-between`}>
      <div className={styles.right}>
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <img src={icon} alt="card icon" />
          <h6 style={{ color: colorPalette.text.title }}>{title}</h6>
        </div>
        <span style={{ color: colorPalette.text.op_7 }}>امروز</span>
      </div>
      <div className={styles.right}>
        <h6 style={{ color: "#525252", fontSize: "12px" }}>تعداد:</h6>
        <span style={{ color: colorPalette.text.title }}>{quantity} عدد</span>
      </div>
      <div className={styles.right}>
        <h6 style={{ color: "#525252", fontSize: "12px" }}>مبلغ:</h6>
        <span style={{ color: colorPalette.text.title }}>
          {Number(value).toLocaleString()} تومان
        </span>
      </div>
    </div>
  );
};

export default DashboardCard;
