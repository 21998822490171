export const STATUS_DICTIONARY = {
  need_payment: {
    color: '#FFC521',
    label: 'در انتظار پرداخت',
  },
  pending: {
    color: '#FFC521',
    label: 'نیاز به بررسی',
  },
  processing: {
    color: '#FFC521',
    label: 'در حال پردازش',
  },
  sending: {
    color: '#FFC521',
    label: 'در حال ارسال',
  },
  delivery_pending: {
    color: '#FFC521',
    label: 'در انتظار ارسال',
  },
  canceled: {
    color: '#FF3C3C',
    label: 'لغو شده',
  },
  completed: {
    color: '#28a745',
    label: 'تکمیل شده',
  },
  refunded: {
    color: '#FF3C3C',
    label: 'مسترد شده',
  },
  failed: {
    color: '#FF3C3C',
    label: 'ناموفق',
  },
  success: {
    color: '#28a745',
    label: 'موفق',
  },
  temp: {
    color: 'grey',
    label: 'در انتظار پرداخت',
  },
}

export const ALOPEYK_STATUS_DICTIONARY = {
  new: {
    color: '#FFC521',
    label: 'در انتظار پرداخت',
  },
  searching: {
    color: '#FFC521',
    label: 'نیاز به بررسی',
  },
  picking: {
    color: '#FFC521',
    label: 'در حال پردازش',
  },
  accepted: {
    color: '#FFC521',
    label: 'در حال ارسال',
  },
  delivering: {
    color: '#FFC521',
    label: 'در انتظار ارسال',
  },
  cancelled: {
    color: '#FF3C3C',
    label: 'لغو شده',
  },
  finished: {
    color: '#28a745',
    label: 'تکمیل شده',
  },
  expired: {
    color: '#FF3C3C',
    label: 'مسترد شده',
  },
  delivered: {
    color: '#28a745',
    label: 'موفق',
  },
  scheduled: {
    color: 'grey',
    label: 'در انتظار پرداخت',
  },
}

export const REASON_DICTIONARY = {
  wallet: 'کیف پول',
  order: 'سفارش',
}
