import API from "utils/API";

const GET_WAREHOUSE_LIST = () => `/warehouse/warehouse/`;
const DELETE_WAREHOUSE = (id) => `/warehouse/warehouse/${id}/`;
const GET_WAREHOUSE = (id) => `/warehouse/warehouse/${id}/`;

export const GetWarehouses = (params = {}) => {
  return API.get(GET_WAREHOUSE_LIST(), { params: params });
};
export const getWarehousesOptions = async (data) => {
  return API.options(GET_WAREHOUSE_LIST(), data);
};

export const deleteWarehouse = (id) => {
  return API.delete(DELETE_WAREHOUSE(id));
};

export const getWarehouse = (id) => {
  return API.get(GET_WAREHOUSE(id));
};
export const updateWarehouse = (id, data) => {
  return API.patch(GET_WAREHOUSE(id), data);
};
export const createNewWarehouse = (data) => {
  return API.post(GET_WAREHOUSE_LIST(), data);
};
