import React from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import styles from './styles.module.css'

const CodarxLink = ({ ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <Link
      style={{ color: colorPalette.primary.op_3 }}
      className={styles.codarx_link}
      {...props}
    >
      {props.children}
    </Link>
  )
}

export default CodarxLink
