const navItems = [
  {
    title: 'داشبورد',
    icon: 'iconly-Home',
    path: '/',
  },
  {
    title: 'محصولات',
    icon: 'iconly-Category',
    hasSubmenu: true,
    key: '/products',
    subMenu: [
      {
        title: 'محصولات',
        path: '/products',
      },
      {
        title: 'دسته‌بندی ها',
        path: '/categories',
      },
      // {
      //   title: "مدیریت موجودی",
      //   path: "/categories",
      // },
      {
        title: 'قیمت و موجودی',
        icon: 'iconly-Category',
        path: '/vendor-product',
      },
    ],
  },
  {
    title: 'کاربران',
    icon: 'iconly-User2',
    path: '/customers',
  },
  {
    title: 'سوپرمارکت ها',
    icon: 'iconly-Buy',
    path: '/supermarkets',
  },
  {
    title: 'فروشگاه',
    icon: 'iconly-Bag',
    key: '/vendors',
    hasSubmenu: true,
    subMenu: [
      {
        title: 'فروشگاه ها',
        path: '/vendors',
      },
      {
        title: 'مناطق',
        path: '/regions',
      },
    ],
  },
  {
    title: 'سفارشات',
    icon: 'iconly-Buy',
    path: '/orders',
  },
  {
    title: 'پرداخت‌ها',
    icon: 'iconly-Wallet',
    path: '/payments',
  },
  {
    title: 'درخواست ارسال',
    icon: 'iconly-Send',
    path: '/peyk-requests',
  },
  {
    title: 'پروموشن',
    icon: 'iconly-Ticket',
    hasSubmenu: true,
    key: '/promotions',
    subMenu: [
      {
        title: 'حراج',
        path: '/sales',
      },
      {
        title: 'کد تخفیف',
        path: '/discounts',
      },
      {
        title: 'کارت هدیه',
        path: '/gift-cards',
      },
    ],
  },
  {
    title: 'گزارشات',
    icon: 'iconly-Chart',
    link: 'https://metabase.shinebag.ir/',
  },
  {
    title: 'تنظیمات',
    icon: 'iconly-Setting',
    path: '/settings',
  },
]

export default navItems
