import { Modal } from 'antd'
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const CustomModal = styled(Modal)`
  & .ant-modal-body {
    padding: ${(props) => (props.isMobile ? '24px 16px' : '24px')};
  }
  & .ant-modal-content {
    background: #ffffff;
    box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }
  & .ant-btn-primary {
    border-radius: 4px;
    width: 100px;
    height: 40px;
    disabled: ${(props) => props.actiondisabled};
    background-color: ${(props) => props.okColor};
    margin-right: 24px !important;
  }
  & .ant-btn-default {
    width: 100px;
    height: 40px;
    color: ${(props) => props.cancelColor};
    border: 1px solid ${(props) => props.cancelColor};
    border-radius: 4px;
  }
  & .ant-modal-header {
    border: none;
  }
  & .ant-modal-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 31px;
    color: ${(props) => props.titleColor};
  }
  & .ant-modal-footer {
    border: none;
    border-top: ${(props) => (props.border ? props.border : 'none')};
  }
  & .ant-modal-close-x {
    display: none;
  }
`

const CodarxModal = (props) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <CustomModal
      titleColor={colorPalette.text.main}
      okColor={colorPalette.primary.main}
      cancelColor={colorPalette.primary.main}
      isMobile={props.isMobile ? true : false}
      confirmLoading={props.loading}
      okButtonProps={{
        disabled: props.actionDisabled,
      }}
      {...props}
    >
      {props.children}
    </CustomModal>
  )
}

CodarxModal.propTypes = {
  ...Modal.propTypes,
}

export default CodarxModal
