import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { actionTypes } from './actions'

import colorPalette from '../../styles/theme/colorPalette'

const initialThemeState = {
  type: 'light',
  palette: colorPalette.light,
}

export const reducer = persistReducer(
  {
    storage,
    key: 'panel-theme',
    whitelist: ['theme'],
  },
  (state = initialThemeState, action) => {
    switch (action.type) {
      case actionTypes.SET_THEME:
        return {
          ...state,
          type: action.payload,
          palette: colorPalette[action.payload],
        }

      default:
        return state
    }
  },
)
