import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const H1Title = styled.h1`
  font-weight: 500;
  font-size: 24px;
  line-height: 38px;
  color: ${({ colorPalette }) => colorPalette.text.title};
  margin-bottom: 0;
`

const Title = (props) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return <H1Title colorPalette={colorPalette}>{props.text}</H1Title>
}

Title.propTypes = {
  text: PropTypes.string,
}

Title.defaultProps = {
  text: '',
}

export default Title
