import styled from "styled-components";
import { Table } from "antd";

export const TableStyles = styled.div`
  border-radius: 8px;
  background-color: #ffffff;
  position: relative;
  .pr_title {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 38px;
    padding-bottom: 23px;
  }
  .pr_header_col {
    padding-bottom: 23px;
  }
  .pr_table_footer {
    display: flex;
    align-items: center;
    padding: ${({ isTabletOrMobile }) =>
      isTabletOrMobile ? "24px 0px 26px 0" : "24px 26px 26px 32px"};
  }
  .pr_table_pagination {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
  }
  .pr_table_rows {
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    margin-left: 17px;
  }
  .pr_table_title {
    margin-right: 37px;
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    height: 60px;
    width: 150px;
    padding: 15px 0px 37px 0px;
  }

  .pr_table_header {
    padding: 19px 26px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .pr_serach {
    box-sizing: border-box;
    border-radius: 4px;
    flex-grow: 1;
    max-width: 344px;
    margin-right: 16px;
  }
  .pr_serach_input {
    padding: 9px 16px 10px 16px;
    box-sizing: border-box;
    border-radius: 4px;
  }
`;
export const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: ${({ colorPalette }) => colorPalette.text.title};
    background-color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    border-bottom: 1px solid ${({ colorPalette }) => colorPalette.text.op_8};
    height: 64px;
    padding: 24px 26px 15px 26px;
  }
  & .ant-table-body tr:nth-child(2n + 1) {
    background-color: #fafafa;
  }

  & .ant-table-body tr:hover > td {
    background: unset !important;
  }
  .ant-table-tbody > tr > td {
    height: 85px;
    padding: 0px 26px;
    cursor: pointer;
  }
  .ant-table-cell::before {
    display: none;
  }
`;
