import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import styles from './styles.module.css'
import { IconButton } from '../..'

const DiscountCodeCard = ({}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <div
      style={{ color: colorPalette.text.main }}
      className={styles.discount_code_card_container}
    >
      <div className={styles.discount_code_card_header}> کد تخفیف</div>
      <div
        className={styles.discount_code_card_layout}
        style={{ borderBottom: `1px solid ${colorPalette.text.op_9}` }}
      >
        <div className={styles.discount_code_card_flex_layout}>
          <div className={styles.discount_code_card_col_layout}>
            <div
              style={{ color: colorPalette.text.cp_2 }}
              className={styles.discount_code_card_code}
            >
              Ykjdwirncda
            </div>
            <div className={styles.discount_code_card_text}>40% </div>
          </div>

          <IconButton
            type={'danger'}
            icon={
              <i
                className="iconly-Delete"
                style={{ color: colorPalette.primary.main }}
              ></i>
            }
          />
        </div>
        <div className={styles.discount_code_card_text}>چتل A </div>
        <div className={styles.discount_code_card_text}>دسته بندی،دستهبندی</div>
      </div>
      <div className={styles.discount_code_card_layout}>
        <div className={styles.discount_code_card_flex_layout}>
          <div className={styles.discount_code_card_col_layout}>
            <div
              style={{ color: colorPalette.text.cp_2 }}
              className={styles.discount_code_card_code}
            >
              Ykjdwirncda
            </div>
            <div className={styles.discount_code_card_text}>40% </div>
          </div>

          <IconButton
            type={'danger'}
            icon={
              <i
                className="iconly-Delete"
                style={{ color: colorPalette.primary.main }}
              ></i>
            }
          />
        </div>
        <div className={styles.discount_code_card_text}>چتل A </div>
        <div className={styles.discount_code_card_text}>دسته بندی،دستهبندی</div>
      </div>
    </div>
  )
}
DiscountCodeCard.prototype = {}
export default DiscountCodeCard
