import API from 'utils/API'

const CREATE_FILE_URL = () => `/file_manager/file_manager/`
const UPDATE_FILE_URL = (id) => `/file_manager/file_manager/${id}/`

export const CreateNewFile = (data) => {
  return API.post(CREATE_FILE_URL(), data)
}

export const UpdateFile = (id, data) => {
  return API.patch(UPDATE_FILE_URL(id), data)
}

export const DeleteFile = (id) => {
  return API.delete(UPDATE_FILE_URL(id))
}
