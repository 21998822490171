export const actionTypes = {
  SET_CHANNELS: '[Set Channels] Action',
  SET_WAREHOUSES: '[Set Warehouses] Action',
}

export const actions = {
  setChannel: (channels) => ({
    type: actionTypes.SET_CHANNELS,
    payload: channels,
  }),
  setWarehouse: (warehouses) => ({
    type: actionTypes.SET_WAREHOUSES,
    payload: warehouses,
  }),
}
