import React from 'react'
import { useMediaQuery } from 'utils/useMediaQuery'

import styles from './styles.module.css'

const CodarxActionRow = ({
  containerStyles,
  okComp,
  cancelComp,
  deleteComp,
}) => {
  const isTabletOrMobile = useMediaQuery('(max-width:992px)')

  return (
    <div
      style={{
        ...containerStyles,
        padding: isTabletOrMobile && '8px 16px',
        width: isTabletOrMobile && '100%',
        right: isTabletOrMobile && 0,
      }}
      className={styles.container}
    >
      {!isTabletOrMobile ? (
        <div style={{ width: '100%' }} className="flex-row-between">
          <div style={{ width: 100 }}>{deleteComp}</div>
          <div className={'flex-row-between'}>
            <div style={{ width: 100, marginLeft: 16 }}>{okComp}</div>
            <div style={{ width: 100 }}>{cancelComp}</div>
          </div>
        </div>
      ) : (
        <div style={{ width: '100%' }}>
          <div style={{ width: '100%', marginBottom: 24 }}>{okComp}</div>
          <div style={{ width: '100%', marginBottom: 24 }}>{deleteComp}</div>
          <div style={{ width: '100%' }}>{cancelComp}</div>
        </div>
      )}
    </div>
  )
}

export default CodarxActionRow
