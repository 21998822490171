import React from "react";
import { Link, useLocation } from "react-router-dom";
import SidebarStyles from "./style";
import { CodarxLogo } from "assets/images";
import { useSelector } from "react-redux";
import NavMenu from "../navigation/NavMenu";
import NavItems from "../navigation/NavItems";

const SidebarContent = () => {
  return (
    <SidebarStyles>
      <div className="logo_wrapper">
        <Link to="/">
          <img
            src="https://codarx.com/wp-content/uploads/2024/02/codarx-logo.svg"
            width={48}
            alt="logo"
          />
        </Link>
      </div>
      <NavMenu Items={NavItems} />
    </SidebarStyles>
  );
};

export default SidebarContent;
