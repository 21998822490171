import { ConfigProvider } from "antd";
import React, { Suspense, useEffect } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import Routes from "./Routes";
import { ErrorBoundary } from "react-error-boundary";

import { SkeletonTheme } from "react-loading-skeleton";
import { QueryClient, QueryClientProvider } from "react-query";
import fa_IR from "antd/es/locale/fa_IR";

import AuthProvider from "./providers/AuthProvider";
import { setUpInterceptorStore } from "./utils/API";

import { SplashScreen } from "./components";
import {
  setupBaseVaraiables,
  _changeLayoutBackground,
  _changeLayoutNumbers,
} from "./utils/_helpers";

const queryClient = new QueryClient();

const App = ({ store, persistor }) => {
  // set up store for axios interceptors
  setUpInterceptorStore(store);

  const palette = store.getState().theme.palette;

  // set up colorpalette helpers function
  setupBaseVaraiables(palette);

  ConfigProvider.config({
    prefixCls: "ant",
    theme: {
      primaryColor: palette.primary.main,
      successColor: palette.success.main,
      warningColor: palette.warning.main,
      errorColor: palette.danger.main,
    },
  });

  return (
    // Redux State Management Provider
    <Provider store={store}>
      {/* Redux Persistor Gate */}
      <PersistGate persistor={persistor} loading={<SplashScreen />}>
        {/* Splash Screen Fallback */}
        <Suspense fallback={<SplashScreen />}>
          {/* Query Client Provider */}
          <QueryClientProvider client={queryClient}>
            {/* Ant Design Theme Provider */}
            <ConfigProvider prefixCls="ant" direction="rtl" locale={fa_IR}>
              {/* React Router */}
              <BrowserRouter>
                {/* Authentication Flow Provider */}
                <AuthProvider>
                  {/* Skeleton Loading Theme PRovider */}
                  <SkeletonTheme borderRadius={8}>
                    <ErrorBoundary
                      onError={() => {
                        window.location.reload();
                      }}
                      fallback={() => <div></div>}
                    >
                      <Routes />
                    </ErrorBoundary>
                  </SkeletonTheme>
                </AuthProvider>
              </BrowserRouter>
            </ConfigProvider>
          </QueryClientProvider>
        </Suspense>
      </PersistGate>
    </Provider>
  );
};

export default App;
