import React, { useEffect, useState } from 'react'
import { Button, Input, Select } from 'antd'
import { Pagination, CodarxDropDown, CustomersFilters } from '..'
import { useSelector } from 'react-redux'
import { TableStyles, StyledTable } from './style'
import { tableBodyParser } from 'utils/_helpers'
import { useNavigate, useLocation } from 'react-router-dom'
import 'react-perfect-scrollbar/dist/css/styles.css'
import { useMediaQuery } from 'utils/useMediaQuery'
import Skeleton from 'react-loading-skeleton'
import { OrderFilters } from 'components/tableFilters'

const options = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
]

const CodarxTable = ({
  fields,
  tableData,
  searchPlaceHolder,
  hasCustomFilters,
  hasSearch = true,
  totalResults,
  tableTitle,
  disableRowOnClick,
  loading,
  targetField = 'id',
  onRowClick = null,
  hasTabs = true,
  hasPagination = true,
  hasHeader = true,
  filter = null,
  isScrollable = true,
  onChange,
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)
  const { pathname, state } = useLocation()
  const [searchText, setSearchText] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useState(searchText)
  
  const navigate = useNavigate()

  const parameters = state || {}

  const isTabletOrMobile = useMediaQuery('(max-width: 1200px)')

  useEffect(() => {
    const timer = setTimeout(() => setSearchText(debouncedSearch), 1000)
    return () => clearTimeout(timer)
  }, [debouncedSearch])

  useEffect(() => {
    if (searchText.length > 0)
      navigate('', { state: { ...parameters, page: 1, search: searchText } })
  }, [searchText])

  return (
    <TableStyles isTabletOrMobile={isTabletOrMobile}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {hasTabs && (
          <div
            className="pr_table_title"
            style={{
              color: colorPalette.text.title,
              textAlign: 'center',
              borderBottom: '2px solid ' + colorPalette.primary.op_1,
            }}
          >
            {tableTitle}
          </div>
        )}
        {hasSearch && (
          <div
            className="pr_serach"
            style={{
              border: '1px solid ' + colorPalette.text.op_7,
              marginLeft: '1rem',
            }}
          >
            <Input
              bordered={false}
              placeholder={searchPlaceHolder}
              className="pr_serach_input"
              defaultValue={parameters?.search}
              suffix={<i className="iconly-Search" style={{ scale: '1.5' }} />}
              onChange={(e) => {
                setDebouncedSearch(e.target.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  navigate('', {
                    state: { ...parameters, search: searchText, page: 1 },
                  })
                }
              }}
            />
          </div>
        )}
      </div>
      {filter && <>{filter}</>}
      {/* {hasHeader && <OrderFilters />} */}
      {loading && (
        <Skeleton
          style={{
            position: 'absolute',
            padding: '0px 16px',
            borderRadius: 0,
            height: '100%',
            minHeight: 600,
          }}
          width={'100%'}
        />
      )}
      <StyledTable
        columns={fields}
        dataSource={tableBodyParser(tableData)}
        pagination={false}
        colorPalette={colorPalette}
        scroll={isScrollable ? { x: 1200, y: 500 } : { y: 500 }}
        onChange={(p, f, sorter) => {
          if (sorter) {
            if (!sorter.order) {
              const { ordering, ...newParams } = parameters
              navigate('', { replace: true, state: { ...newParams, page: 1 } })
            } else if (sorter.order == 'ascend') {
              navigate('', {
                replace: true,
                state: { ...parameters, ordering: sorter.field, page: 1 },
              })
            } else {
              navigate('', {
                replace: true,
                state: {
                  ...parameters,
                  ordering: '-' + sorter.field,
                  page: 1,
                },
              })
            }
          }
        }}
        onRow={(record) => {
          return disableRowOnClick
            ? {}
            : {
                onClick: () => {
                  if (onRowClick) {
                    onRowClick(record)
                  } else {
                    navigate(`${pathname}/single/${record[targetField]}`)
                  }
                },
              }
        }}
      />
      {hasPagination && (
        <div
          className="pr_table_footer"
          style={{ flexDirection: isTabletOrMobile ? 'column' : 'row' }}
        >
          <div
            className="pr_table_pagination"
            style={{ marginBottom: isTabletOrMobile ? '24px' : '0px' }}
          >
            <Pagination
              totalResults={totalResults}
              page={parameters.page ? parameters.page : 1}
              pageSize={parameters.page_size ? parameters.page_size : 10}
            />
          </div>
          <div style={{ margin: '0 1rem' }}>
            تعداد کل اطلاعات: <span>{totalResults}</span>
          </div>
          <div>
            <span
              style={{ color: colorPalette.text.title }}
              className="pr_table_rows"
            >
              تعداد سطرها :
            </span>
            <CodarxDropDown
              options={options}
              currentValue={{
                value: parameters.page_size ? parameters.page_size : 10,
                key: parameters.page_size,
              }}
              type="contained"
              onChange={(newPageSize) => {
                navigate('', {
                  state: { ...parameters, page_size: newPageSize },
                })
              }}
            />
          </div>
        </div>
      )}
    </TableStyles>
  )
}

CodarxTable.defaultProps = {
  searchPlaceHolder: 'جست و جو محصول',
  hasCustomFilters: true,
  tableTitle: '',
}

export default CodarxTable
