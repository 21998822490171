import API from 'utils/API'

const BASIC_LOGIN_URL = '/account/auth/token/'
const GET_ME_URL = `/account/user/my_profile/`
const LOGOUT_BLACKLIST_URL = '/account/auth/logout/'
const GET_REFRESH_URL = '/account/auth/refresh/'
const SEND_OTP_URL = '/account/auth/get_otp/'
const CHECK_OTP_URL = '/account/auth/check_otp/'

export const BasicLogin = (data) => {
  return API.post(BASIC_LOGIN_URL, data)
}

export const GetUserByToken = () => {
  return API.get(GET_ME_URL)
}

export const LogoutBlacklist = () => {
  return API.post(LOGOUT_BLACKLIST_URL)
}

export const GetUserByRefresh = (refresh) => {
  return API.post(GET_REFRESH_URL, { refresh })
}

export const SendOTPCode = (data) => {
  return API.post(SEND_OTP_URL, data)
}

export const CheckOTPCode = (data) => {
  return API.post(CHECK_OTP_URL, data)
}
