import React, { useState } from 'react'
import { Table, Radio, Divider, Input, Row, Col } from 'antd'
import styles from '../../pages/CustomersPage/pages/Customers/styles.module.css'
import { Pagination, CodarxDropDown, CustomersFilters } from '..'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

const columns = [
  {
    title: 'نام و نام خانوادگی',
    dataIndex: 'name',
  },
  {
    title: 'ایمیل',
    dataIndex: 'email',
  },
  {
    title: 'شماره موبایل',
    dataIndex: 'phone',
  },
]
const data = [
  {
    key: '1',
    name: 'فریبرز خسروی',
    email: 'mehdi6583@gmail.com',
    phone: '09196686583',
  },
  {
    key: '2',
    name: 'فریبرز خسروی',
    email: 'mehdi6583@gmail.com',
    phone: '09196686583',
  },
  {
    key: '4',
    name: 'فریبرز خسروی',
    email: 'mehdi6583@gmail.com',
    phone: '09196686583',
  },
  {
    key: '3',
    name: 'فریبرز خسروی',
    email: 'mehdi6583@gmail.com',
    phone: '09196686583',
  },
]

const options = [
  { value: 10, label: 10 },
  { value: 25, label: 25 },
  { value: 50, label: 50 },
]
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {},
  getCheckboxProps: (record) => ({
    disabled: record.name === 'Disabled User',
    name: record.name,
  }),
}
const StyledTable = styled(Table)`
  .ant-table-thead > tr > th {
    color: ${({ colorPalette }) => colorPalette.text.title};
    background-color: #ffffff;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    border: none;
    border-bottom: 1px solid ${({ colorPalette }) => colorPalette.text.op_8};
    height: 64px;
    padding: 24px 26px 15px 26px;
  }
  .ant-table-tbody > tr > td {
    height: 85px;
    padding: 0px 26px;
  }
  .ant-table-cell::before {
    display: none;
  }
`
const CustomersTable = () => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <div className={styles.users_layout}>
      <div
        className={styles.users_table_title}
        style={{
          color: colorPalette.text.title,
          borderBottom: '2px solid ' + colorPalette.primary.op_1,
        }}
      >
        همه کاربران
      </div>
      <div
        className={styles.users_table_header}
        style={{
          borderTop: '1px solid ' + colorPalette.text.op_8,
          borderBottom: '1px solid ' + colorPalette.text.op_8,
        }}
      >
        <CustomersFilters />

        <div
          className={styles.users_serach}
          style={{ border: '1px solid ' + colorPalette.text.op_7 }}
        >
          <Input
            bordered={false}
            placeholder="جست و جو کاربر"
            className={styles.users_serach_input}
          />
        </div>
      </div>
      <StyledTable
        colorPalette={colorPalette}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <div className={styles.users_table_footer}>
        <div className={styles.users_table_pagination}>
          <Pagination />
        </div>
        <div>
          <span
            style={{ color: colorPalette.text.title }}
            className={styles.users_table_rows}
          >
            تعداد سطرها :
          </span>
          <CodarxDropDown
            options={options}
            currentValue={options[0]}
            type="contained"
          />
        </div>
      </div>
    </div>
  )
}
export default CustomersTable
