import { Input } from 'antd'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import styles from './styles.module.css'
import { DangerVector, WarningVector } from 'assets/images'

const BaseInput = styled(Input)`
  color: ${(props) => props.titleColor};
  width: 100%;
  height: 22px;
  padding: 4px 0px !important;
  text-align: right;
  margin-top: 8px;
  direction: ltr;
  & .pass_toggle {
    font-size: 22px;
    cursor: pointer;
    position: absolute;
    left: 12px;
    top: calc(50% - 10px);
  }
`

const CodarxInput = ({
  width,
  height,
  placeholder,
  message,
  type,
  bgContainer,
  ...props
}) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const [focused, setFocused] = useState(false)
  const [secured, setSecured] = useState(true)

  const InputRef = useRef()

  const [isOccupied, setIsOccupied] = useState(focused || props.value)

  const _getBaseColor = () => {
    if (message) {
      switch (message.type) {
        case 'info':
          return colorPalette.primary.op_10
        case 'warning':
          return colorPalette.warning.op_4
        case 'error':
          return colorPalette.danger.op_1

        default:
          return colorPalette.primary.op_10
      }
    } else {
      return isOccupied ? colorPalette.primary.op_10 : colorPalette.text.op_5
    }
  }

  useEffect(() => {
    let cond = focused || props.value
    cond != isOccupied && setIsOccupied(cond)
  }, [focused, props.value])

  return (
    <div className={`${styles.content_container} codarx_input`}>
      <div
        onClick={() => InputRef.current.focus()}
        style={{
          width,
          height,
          border: `1px solid ${_getBaseColor()}`,
          background: bgContainer,
        }}
        className={styles.input_container}
      >
        <BaseInput
          ref={InputRef}
          dir="rtl"
          type={type == 'password' ? (secured ? 'password' : 'text') : type}
          onFocus={() => setFocused(true)}
          bordered={false}
          titleColor={colorPalette.text.title}
          {...props}
          onBlur={(e) => {
            setFocused(false)
            props.onBlur && props.onBlur(e)
          }}
        />
        <span
          style={{
            color: isOccupied ? _getBaseColor() : colorPalette.text.op_3,
            fontSize: isOccupied ? 10 : 14,
            top: isOccupied ? 4 : 'calc(50% - 11px)',
          }}
          className={styles.placeholder}
        >
          {placeholder}
        </span>
        {type == 'password' && (
          <i
            style={{ color: colorPalette.text.op_3 }}
            onClick={() => setSecured(!secured)}
            className={`${styles.pass_toggle} ${
              secured ? 'iconly-Hide' : 'iconly-Show'
            }`}
          ></i>
        )}
      </div>
      {message && (
        <div
          style={{ color: colorPalette.text.op_3 }}
          className={styles.message_box}
        >
          {message.rightComponent ? (
            <div>
              <img
                style={{ height: 13, marginLeft: 3 }}
                src={message.type == 'error' ? DangerVector : WarningVector}
              />
              {message.rightComponent}
            </div>
          ) : (
            <span></span>
          )}
          {message.leftComponent ? message.leftComponent : <span></span>}
        </div>
      )}
    </div>
  )
}

CodarxInput.propTypes = {
  placeholder: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  message: PropTypes.object,
  ...Input.propTypes,
}

CodarxInput.defaultProps = {
  placeholder: '',
  height: 52,
  bgContainer: 'transparent',
}

export default CodarxInput
