import API from "utils/API";

const GET_VENDOR_LIST = () => `/vendor/admin/vendor/`;
const GET_SINGLE_VENDOR = (id) => `/vendor/admin/vendor/${id}/`;
const GET_VENDOR_PRODUCTS_URL = () => `/product/admin/vendor_product/`;
const GET_VENDOR_REGIONS_URL = () => `/vendor/admin/region/`;
const GET_VENDOR_HOLIDAY = "/vendor/admin/vendor_holiday/";
const POST_VENDOR_HOLIDAY = "/vendor/admin/vendor_holiday/";
const DELETE_VENDOR_HOLIDAY = (id) => `/vendor/admin/vendor_holiday/${id}/`;
const GET_VENDOR_UPDATE_AREA = (id) =>
  `/vendor/admin/vendor/${id}/update_area/`;

const POST_VENDOR_PRODUCTS_URL = `/product/admin/vendor_product/`;

export const GetVendors = (params = {}) => {
  return API.get(GET_VENDOR_LIST(), { params });
};

export const PostVendors = (body) => {
  return API.post(POST_VENDOR_PRODUCTS_URL, body);
};

export const GetSingleVendor = (id) => {
  return API.get(GET_SINGLE_VENDOR(id));
};

export const DeleteVendor = (id) => {
  return API.delete(GET_SINGLE_VENDOR(id));
};

export const UpdateVendor = (id, data) => {
  return API.put(GET_SINGLE_VENDOR(id), data);
};

export const CreateSingleVendor = (data) => {
  return API.post(GET_VENDOR_LIST(), data);
};

export const GetVendorProducts = (params) => {
  return API.get(GET_VENDOR_PRODUCTS_URL(), { params });
};

export const GetVendorRegions = (params) => {
  return API.get(GET_VENDOR_REGIONS_URL(), {params});
};

export const GetVendorHolidays = ({ params }) => {
  return API.get(GET_VENDOR_HOLIDAY, { params });
};

export const GetVendorByIdUpdateArea = ({ id }) => {
  return API.get(GET_VENDOR_UPDATE_AREA(id));
};

export const PostVendorHolidays = ({ body }) => {
  return API.post(POST_VENDOR_HOLIDAY, body);
};

export const DeleteVendorHolidayById = ({ id }) => {
  return API.delete(DELETE_VENDOR_HOLIDAY(id));
};
