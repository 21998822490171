import React, { useRef, useEffect, useState } from 'react'
import { shallowEqual, useSelector, connect, useDispatch } from 'react-redux'
import { actions as authActions } from '../store/authRedux/actions'
import { actions as commonActions } from '../store/commonRedux/actions'
import { SplashScreen } from 'components'
import {
  GetUserByRefresh,
  GetUserByToken,
  LogoutBlacklist,
} from 'services/auth'
import { getChannels } from 'services/misc'
import { GetWarehouses } from 'services/warehouse'
import { notification } from 'antd'

function AuthProvider(props) {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const { authToken, refreshToken, isAuthorized } = useSelector(
    ({ auth }) => ({
      authToken: auth.access,
      refreshToken: auth.refresh,
      isAuthorized: auth.user != null,
    }),
    shallowEqual,
  )

  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          if (refreshToken !== undefined) {
            // init auth
            const access = await GetUserByRefresh(refreshToken)
            dispatch(props.refresh(access.access))
            const user = await GetUserByToken()
            dispatch(props.fulfillUser({ ...user }))

            // init channels
            const channels = await getChannels()
            dispatch(commonActions.setChannel(channels.results))
          } else {
            dispatch(props.logout())
          }
        }
      } catch (error) {
        if (!error.response) {
          notification.error({
            message: 'خطا در اتصال به اینترنت',
          })
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    if (authToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
  }, [refreshToken])

  return showSplashScreen ? <SplashScreen /> : <>{props.children}</>
}

export default connect(null, authActions)(AuthProvider)
