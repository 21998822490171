import { Button } from 'antd'
import React from 'react'
import styled from 'styled-components'
import styles from './styles.module.css'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const CustomButton = styled(Button)`
  border: none;
  box-shadow: none !important;
  &:hover {
    border: none !important;
    background: ${({ backColor }) => backColor} !important;
  }
  & i {
    color: ${({ foreColor }) => foreColor} !important;
  }
`

const IconButton = ({ icon, style, ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const _getForeColor = () => {
    switch (props.type) {
      case 'primary':
        return colorPalette.primary.main

      case 'danger':
        return colorPalette.danger.op_1

      default:
        return colorPalette.primary.main
    }
  }

  const _getBackColor = () => {
    switch (props.type) {
      case 'primary':
        return colorPalette.primary.op_8

      case 'danger':
        return colorPalette.danger.op_5

      default:
        return colorPalette.primary.op_8
    }
  }

  return (
    <CustomButton
      className={styles.icon_button}
      style={{ color: _getForeColor(), ...style }}
      backColor={_getBackColor()}
      foreColor={_getForeColor()}
      ghost={true}
      icon={icon}
      {...props}
    />
  )
}

IconButton.propTypes = {
  icon: PropTypes.element,
}

export default IconButton
