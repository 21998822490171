import styled from "styled-components";
import { Select, Menu } from "antd";

export const CustomSelect = styled(Select)`
  .ant-select-arrow {
    color: #505151;
  }
  &.conatained {
    border: 1px solid #bebfc1;
    background-color: #fff !important;
    border-radius: 4px;
    padding: 3px 12px;
    min-width: 160px;
    min-height: 40px;
    * {
      outline: none;
      border: none;
      box-shadow: none;
    }
    .ant-select-selector {
      border: none !important;
      outline: none !important;
      background-color: transparent !important;
    }
    .ant-select-selector::focus: {
      border: none !important;
      outline: none !important;
      background-color: transparent !important;
    }
  }
  &.filled {
    width: 160px;
    .ant-select-selector {
      height: 40px;
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
  }
`;
export const MenuBordered = styled(Menu)`
  border: 1px solid #6680ee !important;
  border-radius: 4px;
`;
