import { Col, Form, message, notification, Row } from "antd";
import React, { useState } from "react";
import {
  CodarxBtn,
  CodarxInput,
  CodarxTextArea,
  CodarxModal,
  MapComponent,
  MapMarker,
} from "components";
import { CreateAddressCustomer, EditAddressCustomer } from "services/customer";

import { useFormik } from "formik";
import * as Yup from "yup";
import { _getFieldError, IsUserPermitted } from "utils/_helpers";

const validationSchema = Yup.object({
  address_name: Yup.string()
    .min(1, "نام آدرس باید حداقل یک کاراکتر باشد")
    .required("وارد کردن این فیلد الزامی است"),
  address: Yup.string()
    .min(12, "آدرس باید حداقل 12 کاراکتر باشد")
    .required("وارد کردن این فیلد الزامی است"),
  plate: Yup.string().min(1).required("وارد کردن این فیلد الزامی است"),
  postal_code: Yup.string(),
  unit: Yup.string()
    .min(1, "نام آدرس باید حداقل یک کاراکتر باشد")
    .required("وارد کردن این فیلد الزامی است"),
  phone: Yup.string()
    .matches(
      /^(\+98|0098|98|0)?9\d{9}$/g,
      "لطفا یک شماره موبایل معتبر وارد نمایید"
    )
    .required("وارد کردن این فیلد الزامی است"),
  coordinates: Yup.array().required("وارد کردن این فیلد الزامی است"),
});

const AddressAction = ({
  visible,
  onChangeVisible,
  user,
  title,
  initialValues,
  type,
  onFinish,
  address_id,
}) => {
  const [markerArray, setMarkerArray] = useState([]);
  const [loading, setLoading] = useState(false);

  const _sendData = (values) => {
    const key = "action_address_updatable";
    message.loading({ content: "لطفا چند لحظه صبر کنید", key });
    if (type == "edit") {
      EditAddressCustomer(user, address_id, { ...values, user })
        .then((result) => {
          message.success({ content: "اطلاعات با موفقیت ثبت شد", key });
          onFinish();
          onChangeVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 402) {
            notification.error({
              message:
                Object.keys(err.response.data)[0] +
                " : " +
                err.response.data[Object.keys(err.response.data)[0]],
            });
          }
        });
    } else {
      CreateAddressCustomer({ user, ...values })
        .then((result) => {
          message.success({ content: "اطلاعات با موفقیت ثبت شد", key });
          onFinish();
          onChangeVisible(false);
        })
        .catch((err) => {
          if (err.response.status === 400 || err.response.status === 402) {
            notification.error({
              message:
                Object.keys(err.response.data)[0] +
                " : " +
                err.response.data[Object.keys(err.response.data)[0]],
            });
          }
        });
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: _sendData,
  });

  const mapOnClick = (_, e) => {
    const array = [];
    array.push(
      <MapMarker coordinates={[e.lngLat.lng, e.lngLat.lat]} anchor="bottom" />
    );
    setMarkerArray(array);
    formik.setFieldValue("coordinates", [e.lngLat.lng, e.lngLat.lat]);
  };

  return (
    <>
      <CodarxModal
        title={title}
        visible={visible}
        okText={"ذخیره"}
        onOk={
          IsUserPermitted("address", "change")
            ? () => formik.submitForm()
            : null
        }
        onCancel={() => onChangeVisible(false)}
        actiondisabled={loading}
      >
        <Row gutter={16}>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }} lg={{ span: 12 }}>
            <CodarxInput
              message={_getFieldError(formik, "address_name")}
              value={formik.values.address_name}
              onChange={(e) =>
                formik.setFieldValue("address_name", e.target.value)
              }
              onBlur={() => formik.setFieldTouched("address_name", true)}
              placeholder="نام آدرس"
            />
          </Col>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }} lg={{ span: 12 }}>
            <CodarxInput
              message={_getFieldError(formik, "phone")}
              value={formik.values.phone}
              onChange={(e) => formik.setFieldValue("phone", e.target.value)}
              onBlur={() => formik.setFieldTouched("phone", true)}
              placeholder="شماره تلفن"
            />
          </Col>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }} lg={{ span: 12 }}>
            <CodarxInput
              message={_getFieldError(formik, "plate")}
              value={formik.values.plate}
              onChange={(e) => formik.setFieldValue("plate", e.target.value)}
              onBlur={() => formik.setFieldTouched("plate", true)}
              placeholder="پلاک"
            />
          </Col>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }} lg={{ span: 12 }}>
            <CodarxInput
              message={_getFieldError(formik, "unit")}
              value={formik.values.unit}
              onChange={(e) => formik.setFieldValue("unit", e.target.value)}
              onBlur={() => formik.setFieldTouched("unit", true)}
              placeholder="واحد"
            />
          </Col>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }} lg={{ span: 12 }}>
            <CodarxInput
              message={_getFieldError(formik, "postal_code")}
              value={formik.values.postal_code}
              onChange={(e) =>
                formik.setFieldValue("postal_code", e.target.value)
              }
              onBlur={() => formik.setFieldTouched("postal_code", true)}
              placeholder="کدپستی"
            />
          </Col>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }}>
            <CodarxTextArea
              message={_getFieldError(formik, "address")}
              value={formik.values.address}
              onChange={(e) => formik.setFieldValue("address", e.target.value)}
              onBlur={() => formik.setFieldTouched("address", true)}
              placeholder="آدرس کامل"
            />
          </Col>
          <Col style={{ marginBottom: 24 }} xs={{ span: 24 }}>
            {formik.values.coordinates && (
              <MapComponent
                containerStyle={{
                  borderRadius: 12,
                  height: 350,
                }}
                onDrag={(e) => {
                  setMarkerArray([
                    <MapMarker coordinates={e.getCenter()} anchor="bottom" />,
                  ]);
                }}
                onClick={mapOnClick}
                userLocation={true}
                zoom={[12]}
                center={formik.values.coordinates}
              >
                {markerArray}
              </MapComponent>
            )}
          </Col>
        </Row>
      </CodarxModal>
    </>
  );
};

export default AddressAction;
