import React from 'react'
import { useSelector } from 'react-redux'

import { FormAlert } from '../alerts'
import CodarxInput from '../codarxInput'
import { CodarxBtn } from '../buttons'

import styles from '../../pages/AuthPage/styles.module.css'

const ForgetPassword = () => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  return (
    <div className={styles.form_main}>
      <div className={styles.form_body}>
        <h1 className={styles.title} style={{ color: colorPalette.text.title }}>
          فراموشی رمزعبور{' '}
        </h1>
        <div className={styles.form_alert}>
          <FormAlert
            type={'info'}
            message={
              'لطفا ایمیل خود را وارد کنید تا رمز عبور جدید برای شما ارسال شود.'
            }
          />
        </div>
        <div className={styles.form_input}>
          <CodarxInput block={true} type="text" placeholder={'ایمیل'} />
        </div>
      </div>
      <div className={styles.form_action}>
        <div className={styles.form_button}>
          <CodarxBtn block={true}>ورود</CodarxBtn>
        </div>
        <div className={styles.form_button}>
          <CodarxBtn block={true} ghost={true}>
            ارسال رمز به تلفن همراه
          </CodarxBtn>
        </div>
      </div>
    </div>
  )
}

export default ForgetPassword
