import API from 'utils/API'

const GET_ASSIGNED_PRODUCT_ATTR_URL = () =>
  `/attribute/assigned-product-attribute/`
const UPDATE_ASSIGNED_PRODUCT_ATTR_VALUE_URL = (id) =>
  `/attribute/assigned-product-attribute-value/update_multi_value/`
const DELETE_ASSIGNED_PRODUCT_ATTR_VALUE_URL = (id) =>
  `/attribute/assigned-product-attribute-value/${id}/`
const GET_ASSIGNED_PRODUCT_ATTR_VALUE_URL = () =>
  `/attribute/assigned-product-attribute-value/`
const GET_ASSIGNED_PRODUCT_TYPE_ATTR_URL = () =>
  `/attribute/assigned-product-type-attribute/`
const CHECK_ATTR_EXISTENCE_URL = () => `/attribute/attribute/`
const DELETE_ASSIGNED_PRODUCT_TYPE_ATTR_URL = (id) =>
  `/attribute/assigned-product-type-attribute/${id}/`
const GET_ATTRIBUTE_LIST = '/attribute/attribute/'
const GET_ATTRIBUTE_VALUE_LIST = '/attribute/attribute-value/'

export const GetAttributesList = () => {
  return API.get(GET_ATTRIBUTE_LIST)
}

export const GetAttributeValueList = (id) => {
  return API.get(GET_ATTRIBUTE_LIST, { attribute: id, page_size: 1000 })
}

export const CreateAttributeValueList = (data) => {
  return API.post(GET_ATTRIBUTE_VALUE_LIST, data)
}

export const GetProdcutAssignedAttributes = (product) => {
  return API.get(GET_ASSIGNED_PRODUCT_ATTR_URL(), { params: { product } })
}

export const GetProdcutAssignedAttributesValue = (product) => {
  return API.get(GET_ASSIGNED_PRODUCT_ATTR_VALUE_URL(), {
    params: { product },
  })
}

export const AddProductAssignedAttributeValue = (data) => {
  return API.post(
    GET_ASSIGNED_PRODUCT_ATTR_VALUE_URL() + `assign_multi_value/`,
    data,
  )
}

export const UpdateAssignedAttributValueProduct = (data) => {
  return API.put(UPDATE_ASSIGNED_PRODUCT_ATTR_VALUE_URL(), data)
}

export const DeleteAssignedAttributValueProduct = (id, data) => {
  return API.delete(DELETE_ASSIGNED_PRODUCT_ATTR_VALUE_URL(id))
}

export const GetProductTypeAssignedAttributes = (product) => {
  return API.get(GET_ASSIGNED_PRODUCT_TYPE_ATTR_URL(), { params: { product } })
}

export const CheckAttrExistence = (val) => {
  API.get(CHECK_ATTR_EXISTENCE_URL(), {
    params: {
      search: val,
      search_fields: ['name'],
    },
  })
}
export const CreateProductTypeAssignedAttributes = (data) => {
  return API.post(GET_ASSIGNED_PRODUCT_TYPE_ATTR_URL(), data)
}
export const DeleteProductTypeAssignedAttributes = (id) => {
  return API.delete(DELETE_ASSIGNED_PRODUCT_TYPE_ATTR_URL(id))
}
