import { Alert } from 'antd'
import React from 'react'
import { useSelector } from 'react-redux'

import styles from '../styles.module.css'

const FormAlert = ({ type, ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)

  const _getBaseColor = () => {
    switch (type) {
      case 'success':
        return colorPalette.success.main
      case 'error':
        return colorPalette.danger.op_4
      case 'info':
        return colorPalette.primary.op_7
      case 'warning':
        return colorPalette.warning.op_1

      default:
        return colorPalette.primary.op_7
    }
  }

  return (
    <Alert
      className={styles.alert}
      style={{ backgroundColor: _getBaseColor() }}
      {...props}
    />
  )
}

FormAlert.propTypes = {
  ...Alert.propTypes,
}

export default FormAlert
