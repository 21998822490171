import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Button, DatePicker, Select } from "antd";
import moment from "moment";

const Orderingoptions = [
  { value: "best_selling", label: "پرفروش ترین" },
  { value: "price", label: "ارزانترین" },
  { value: "-price", label: "گران ترین" },
];

const ChannelOptions = [
  { value: process.env.REACT_APP_B2B_CHANNEL_ID, label: "B2B" },
  { value: process.env.REACT_APP_B2C_CHANNEL_ID, label: "B2C" },
];

function ProductFilters() {
  const colorPalette = useSelector(({ theme }) => theme.palette);

  const { state } = useLocation();
  const navigate = useNavigate();

  const [order, setOrder] = useState();
  const [channel, setChannel] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("", {
      state: {
        ...state,
        ordering: order,
        channel
      },
    });
  };

  const handleClearFilters = () => {
    navigate("", {
      state: {
        page: state?.page,
        search: state?.search,
        page_size: state?.page_size,
      },
    });
    setOrder(null);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="pr_table_header"
      style={{
        borderTop: "1px solid " + colorPalette.text.op_8,
        borderBottom: "1px solid " + colorPalette.text.op_8,
        display: "flex",
        gap: "0.5rem",
      }}
    >
      <Select
        style={{ width: 150 }}
        placeholder="مرتب سازی بر اساس"
        options={Orderingoptions}
        type="contained"
        value={order}
        onChange={(val) => {
          setOrder(val);
        }}
      />
      <Select
        style={{ width: 150 }}
        placeholder="کانال محصول"
        options={ChannelOptions}
        type="contained"
        value={channel}
        onChange={(val) => {
          setChannel(val);
        }}
      />
      <Button
        htmlType="submit"
        type="primary"
        style={{ marginRight: "auto", borderRadius: 4 }}
      >
        اعمال فیلترها
      </Button>
      <Button type="text" danger onClick={handleClearFilters}>
        حذف تمامی فیلتر ها
      </Button>
    </form>
  );
}

export default ProductFilters;
