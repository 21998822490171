import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.css'
import { IconButton } from '..'
import { nextIcon, previousIcon } from 'assets/images'
import { Input } from 'antd'
import { useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'

const Pagination = ({ totalResults, page, pageSize, ...props }) => {
  const colorPalette = useSelector(({ theme }) => theme.palette)
  const [focused, setFocused] = useState(false)

  const navigate = useNavigate()
  const { state } = useLocation()

  const _navigate = (page) => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
    navigate('', { state: { ...state, page } })
  }

  return (
    <div className={styles.pagination_container}>
      <IconButton
        disabled={page >= Math.ceil(totalResults / pageSize) ? true : false}
        icon={<img alt="" src={nextIcon} width="7px" height="12px" />}
        onClick={() => {
          _navigate(page + 1)
          props.onChange && props.onChange(page + 1)
        }}
      />
      <div
        className={styles.pagination_input_container}
        style={{ border: `1px solid ${colorPalette.text.op_5}` }}
      >
        <Input
          className={styles.pagination_input}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          bordered={false}
          style={{ color: colorPalette.text.title }}
          key={page}
          defaultValue={page}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault()
            }
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.target.value == '') {
              _navigate(parseInt(e.target.value))
              props.onChange && props.onChange(e.target.value)
            }
          }}
          {...props}
        />
      </div>
      <div
        style={{ color: colorPalette.text.title }}
        className={styles.pagination_text}
      >
        از
        {Math.ceil(totalResults / pageSize) === 0
          ? 1
          : Math.ceil(totalResults / pageSize)}
      </div>
      <IconButton
        icon={<img alt="" src={previousIcon} width="7px" height="12px" />}
        disabled={page < 2 ? true : false}
        onClick={() => {
          _navigate(page - 1)
          props.onChange && props.onChange(page - 1)
        }}
      />
    </div>
  )
}
Pagination.prototype = {}
export default Pagination
